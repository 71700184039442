/*
    {
        fc: "EVENT_STATION_LOADED",
        data: {
            slot: "A",
            station: 1,
            weight: 50,
        }
    }
*/
export enum IvmEventsEnum {
  EVENT_STATION_LOADED = 'STATION_LOADED',
  EVENT_SYSTEM_READY = 'SYSTEM_READY',
  EVENT_UPDATE = 'UPDATE',
  EVENT_ERROR = 'ERROR',
  RETURN_OK = 'OK',
  RETURN_INVALID_CMD = 'INVALID',
  RETURN_SOCKET_IN_USE = 'SOCKET_IN_USE',
  RETURN_ERROR = 'ERROR',
  EVENT_ACK = 'ACK',
  EVENT_IDLE = 'IDLE',
  EVENT_STOP = 'STOP',
  EVENT_RESET = 'RESET',
  EVENT_DOSING_PAUSED = 'DOSING_PAUSED',
  EVENT_DOSING_RESUMED = 'DOSING_RESUMED',
  EVENT_CURTAIN_BREACHED = 'CURTAIN_BREACHED',
  EVENT_CURTAIN_UN_BREACHED = 'CURTAIN_UN_BREACHED',
  EVENT_WEIGHT_REPORT = 'WEIGHTREPORT', //no spaces or underscores!
  EVENT_EMERGENCY_STOP = 'EMERGENCY_STOP',

  EVENT_CASSETTE_INSERTED = 'CASSETTE_INSERTED',
  EVENT_CASSETTE_ROTATED = 'CASSETTE_ROTATED',
  EVENT_CASSETTE_REMOVED = 'CASSETTE_REMOVED',
  EVENT_CANISTER_IDENTIFIED = 'CANISTER_IDENTIFIED',
  EVENT_CANISTER_INSERTED = 'CANISTER_INSERTED',
  EVENT_CANISTER_REMOVED = 'CANISTER_REMOVED',
}

export type IvmEvents = keyof typeof IvmEventsEnum;

/*
    {
        fc: "CMD_START_DOSING",
        data: {
            slot: "A",
            station: 1,
            targetWeight: 50,
        }
    }
*/
export enum IvmCommandsEnum {
  FC_START = 'FC_START',
  FC_EVENT = 'FC_EVENT',
  FC_STOP = 'FC_STOP',
  FC_ACTIVATE = 'FC_ACTIVATE',
  FC_DEACTIVATE = 'FC_DEACTIVATE',
  FC_EMERGENCY_STOP = 'FC_EMERGENCY_STOP',
  FC_RESET = 'FC_RESET',
  FC_ROTATE_TO_RELEASE = 'FC_ROTATE_TO_RELEASE',
  FC_REQUEST_ERROR = 'FC_REQUEST_ERROR',
  FC_START_WEIGHING = 'FC_START_WEIGHING',
  FC_STOP_WEIGHING = 'FC_STOP_WEIGHING',
}

export enum IvmErrorCodesEnum {
  ERROR_NONE = 'ERROR_NONE',
  ERROR_SLOT_NOT_DOSING = 'ERROR_SLOT_NOT_DOSING',
  ERROR_ROTATION = 'ERROR_ROTATION',
  ERROR_SCALE_NO_WEIGHT = 'ERROR_SCALE_NO_WEIGHT',
  ERROR_SCALE_UNEXPECTED_DATA = 'ERROR_SCALE_UNEXPECTED_DATA',
  ERROR_DOSING_STARTED = 'ERROR_DOSING_STARTED',
  ERROR_DOSING_STOPPED = 'ERROR_DOSING_STOPPED',
  ERROR_INCORRECT_WEIGHT = 'ERROR_INCORRECT_WEIGHT',
  ERROR_INCORRECT_STATION = 'ERROR_INCORRECT_STATION',
  ERROR_INCORRECT_SLOT = 'ERROR_INCORRECT_SLOT',
  ERROR_UNJAMMED = 'ERROR_UNJAMMED',
  ERROR_JAMMED = 'ERROR_JAMMED',
}

export type IvmErrorCodes = keyof typeof IvmErrorCodesEnum;

export type IvmCommands = keyof typeof IvmCommandsEnum;

export interface IvmEventData {
  fc: IvmEvents;
  data: {
    slot: string | 'CASSETTE';
    station: number;
    weight: number;
    side?: number | '';
    code?: string;
  };
}

export interface IvmErrorData {
  fc: IvmEvents;
  data: {
    error: IvmErrorCodes;
  };
}

export interface IvmCommandData {
  slot?: string;
  station?: number;
  targetWeight?: number;
  itemWeight?: number;
  error?: IvmErrorCodes;
  qrCode?: string;
}

export const IvmDefinitions = {
  FC_NONE: '0x00', // no message. do nothing.
  FC_START: '0x01', // start dosing
  FC_EVENT: '0x02', // send event back to pc.
  FC_STOP: '0x03', // move the cassette back to 0 and stop dosing
  FC_ACTIVATE: '0x04', // activate specific pneumatic valve. valve umber in slot field
  FC_DEACTIVATE: '0x05', // deactivate specific pneumatic valve. valve umber in slot `field
  FC_EMERGENCY_STOP: '0x06', // Not allowed to call a serial command "emergency", but it is here anyway. Same as stop
  FC_RESET: '0x07', // Resets operation back to normal. Needed when in pause, stop or emergency stop mode.
  FC_ROTATE_TO_RELEASE: '0x08', // Rotates the cassette to the release position (5 or 0). Only allowed in idle, pause, stop or emergency_stop state
  FC_REQUEST_ERROR: '0x09', // request a test error. The immediate response of the IVM should be the requested error.
  FC_START_WEIGHING: '0x09', // Request the IVM to report weight values directly from the scale. This can be used for detection of the cassette insertion/removal or status updates while dosing.
  FC_STOP_WEIGHING: '0x0A', // Request the IVM to stop reporting weight values from the scale.

  // Event codes
  EVENT_STATION_LOADED: '0x00', // the dosing unit has dosed items in the cassette. Weight in milligrams in the data field
  EVENT_ACK: '0x01', // message received and execution will start execution. If not agreed: send error
  EVENT_UPDATE: '0x02', // the dosing unit is busy dosing items in the cassette. Reports the current weight in milligrams in the data field
  EVENT_ERROR: '0x03', // an error has occurred. error code in the data uint16_t
  EVENT_IDLE: '0x04', // The system has returned to idle mode. ready for input.
  EVENT_STOP: '0x05', // The stop button is pressed. System is in stop mode.
  EVENT_RESET: '0x06', // The stop button is released. System goes in RETURN mode and should return in idle mode.
  EVENT_PAUSED: '0x07', // The IVM is paused when the curtain is breached.
  EVENT_RESUMED: '0x08', // When the curtain is not breached anymore, immediately the IVM is resumed.
  EVENT_CURTAIN_BREACHED: '0x09', // The light curtain is breached. if the dosing unit's state machine is not idle, operation is paused. Normal operation is resumed when light curtain unbreached
  EVENT_CURTAIN_UN_BREACHED: '0x0A', // The light curtain is not breached anymore. If operation was paused by the light curtain, it will be resumed now.
  EVENT_WEIGHT_REPORT: '0x0B', // The total weight that the scale measures is reported. data is a single 16bit signed integer and can be negative
  EVENT_EMERGENCY_STOP: '0x0C', //  The stop button is pressed. System is in E-stop mode.  Can only be unstopped by sending EVENT_RESET

  // Error codes
  ERROR_NONE: 0x00, // none
  ERROR_SLOT_NOT_DOSING: 0x01, // slot does not dose. (weight not increasing. possible canister empty or blocked, funnel blocked, station full)
  ERROR_ROTATION: 0x02, // cassette rotation error (possible wrong installation, cassette blocked by item)
  ERROR_SCALE_NO_WEIGHT: 0x03, // Scale error (no new weight reading coming from scale serial input. )
  ERROR_SCALE_UNEXPECTED_DATA: 0x04, // Scale error (unexpected data received. (possibly wrong setting on scale display (units or pieces?)))
  ERROR_DOSING_STARTED: 0x05, // Already dosing. The dosing unit is not in idle state, so can't start dosing.
  ERROR_DOSING_STOPPED: 0x06, // The dosing unit is in stop state. First send a reset command.
  ERROR_DATA_INVALID: 0x07, // The received data is not understood.
  ERROR_INCORRECT_WEIGHT: 0x08, // The weight has to be > 0
  ERROR_INCORRECT_STATION: 0x09, // The station has to be 1..4
  ERROR_INCORRECT_SLOT: 0x0a, // The slot has be either A or B
  ERROR_UNJAMMED: 0x0b, // Unjammed does not sound like an error, but it is. The device is unjammed, but the cassette is probably not fully inserted
  ERROR_JAMMED: 0x0c, // the device is still jammed. Can't be unjammed.

  ERROR_SOCKET_IN_USE: '0x20',
  ERROR_INVALID_CMD: '0x21',
  ERROR_MSG_PARSING_FAILED: '0x22',
  ERROR_DATA_PARSING_FAILED: '0x23',

  // Actuator IDs
  SLOT_A_SLIDE_FRONT: '0x00',
  SLOT_A_SLIDE_BACK: '0x01',
  SLOT_B_SLIDE_FRONT: '0x02',
  SLOT_B_SLIDE_BACK: '0x03',
  SLOT_A_FLIP_UP: '0x04',
  SLOT_A_FLIP_DOWN: '0x05',
  SLOT_B_FLIP_UP: '0x06',
  SLOT_B_FLIP_DOWN: '0x07',
  SLOT_A_OPEN: '0x08',
  SLOT_B_OPEN: '0x09',
};

export enum IvmSimulationsEnum {
  normal = 'normal', //This is the default correct simulation
  estop = 'estop',
  handinserted = 'handinserted',
  nodose = 'nodose',
  norotation = 'norotation',
  toomanyfastenersloaded = 'toomanyfastenersloaded',
  cassetteplaced = 'cassetteplaced',
  cassetteunjammed = 'cassetteunjammed',
  heavycassette = 'heavycassette',
  inoutcassette = 'inoutcassette',
  cassetteremoved = 'cassetteremoved',
  irregularweights = 'irregularweights',
  toomanyfastenersloade = 'toomanyfastenersloade',
  canisterisemptydirectly = 'canisterisemptydirectly',
  canisterisemptyhalfway = 'canisterisemptyhalfway',
  fastenersstuckincanisterandstaystuck = 'fastenersstuckincanisterandstaystuck',
  fastenersstuckincanisterandbecomeunstuck = 'fastenersstuckincanisterandbecomeunstuck',
  reset = 'reset',
  rotatetorelease = 'rotatetorelease',
  none = 'none',
  startweighingfull = 'startweighingfull',
  startweighingempty = 'startweighingempty',
  cassettenotyetplaced = 'cassettenotyetplaced',

  canisterinsertedinslot = 'canisterinsertedinslot',
}

export type IvmSimulations = keyof typeof IvmSimulationsEnum;

export function mapIvmErrorsEnum(error: string | number): string {
  for (const errorCode of Object.values(IvmErrorCodesEnum))
    if (IvmDefinitions[errorCode] === error) return errorCode;

  return error as string;
}
