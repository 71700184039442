import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable, from } from 'rxjs';
import { Token } from '@models/utils/auth-token';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private $refresh: Observable<string> | null = null;

  constructor(private http: HttpClient) {}

  getToken(forceRefresh: boolean): Observable<string | null> {
    return from(this.getRefreshedToken(forceRefresh));
  }

  private async getRefreshedToken(forceRefresh: boolean): Promise<string | null> {
    if (forceRefresh || parseInt(localStorage.getItem('expire_at') ?? '') < Date.now()) {
      // don't call refresh token api multiple times if already refreshing.
      try {
        return await lastValueFrom((this.$refresh ??= this.refreshAccessToken())).finally(
          () => (this.$refresh = null), // clear refresh
        );
      } catch (e) {}
    }

    return localStorage.getItem('token');
  }

  obtainAccessToken(code: string): Observable<Token> {
    let params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', decodeURIComponent(code));
    params.append('code_verifier', localStorage.getItem('codeVerifier') ?? '');
    params.append('client_id', environment.client_id);
    params.append('redirect_uri', environment.redirectUri);

    let options = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Accept': 'application/x-www-form-urlencoded',
    });

    return this.http.post<Token>(environment.apiBaseUrl + '/token', params, { headers: options });
  }

  refreshAccessToken(): Observable<string> {
    let params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', localStorage.getItem('refresh_token') ?? '');
    params.append('client_id', environment.client_id);

    let options = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Accept': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<Token>(environment.apiBaseUrl + '/token', params, { headers: options })
      .pipe(
        map(data => {
          const expiresAt = String(Date.now() + (parseInt(data.expires_in) / 2) * 1000);
          localStorage.setItem('token', data.access_token);
          localStorage.setItem('expire_at', expiresAt);
          localStorage.setItem('refresh_token', data.refresh_token);

          return data.access_token;
        }),
      );
  }
}
