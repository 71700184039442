export type RequestSync = {
  id: number;
  url: string;
  payload: Object;
  type: string;
  attempts: number;
  method: SyncMethod;
};

export type SyncMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

//We use this function to remove the milliseconds from the date, this is required by the backend
export function toIsoString(date?: Date): string | undefined {
  if (!date) return undefined;
  const d = new Date(date);
  if (isNaN(d.getTime())) return undefined; // In case we provide a wrong data
  return d.toISOString().slice(0, -5) + 'Z';
}
