import { Action, createReducer, on } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import { StateToggleStatus } from '@interfaces/utils/state-toggle';
import { StockActions } from './actions';

export interface StockState {
  step1: {
    title: string;
    status: StateToggleStatus;
  };
  step2: {
    title: string;
    status: StateToggleStatus;
  };
  title?: string;
}

const initialState: StockState = {
  step1: {
    title: 'Scan a QR code of a tool or canister',
    status: 'PROCESSING',
  },
  step2: {
    title: 'Done',
    status: 'PENDING',
  },
};

export const reducer = createReducer(
  initialState,
  on(StockActions.initialize, () => initialState),
  mutableOn(StockActions.goToStep1, (state, properties) => {
    state.step1.status = 'PROCESSING';
    state.step2.status = 'PENDING';
  }),
  mutableOn(StockActions.goToStep2, (state, properties) => {
    state.step1.status = 'COMPLETED';
    state.step2.status = 'PROCESSING';
  }),
);

export const StockReducer = (state: StockState | undefined, action: Action): StockState => {
  return reducer(state, action);
};
