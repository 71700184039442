import { Injectable } from '@angular/core';
import { AuditTrailApi } from '@api/audit-trail.api';
import { AuditTrail } from '@models/interfaces/audittrail';
import { AuditTrailRepository } from '@repositories/audit-trail/audit-trail.repository';
import { OrderApiService } from '@services/order/order-api.service';
import { UserService } from '@services/user/user.service';
import { firstValueFrom, mergeMap, switchMap, tap } from 'rxjs';
import { AuditMessage, AuditMessageType, AuditTrailDetail } from './audit-trail.enum';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService {
  constructor(
    private auditTrailApi: AuditTrailApi,
    private auditTrailRepository: AuditTrailRepository,
    private userService: UserService,
    private orderApiService: OrderApiService,
  ) {}

  async addLog(details: AuditTrailDetail, order_id?: string) {
    const { action } = details;
    let message = AuditMessage[action].toString();

    const user = this.userService.getSessionUser();
    for (const key of Object.keys(details)) {
      message = message.replace(`{{${key}}}`, (details as unknown as Record<string, string>)[key]);
    }
    let auditTrailOrderId = order_id;

    if (order_id && !order_id.includes('/api/')) {
      const order = await firstValueFrom(this.orderApiService.findByOrderNumber(order_id));
      auditTrailOrderId = order?.['@id'];
    }

    const logRow: AuditTrail = {
      id: Date.now().toString(),
      action,
      user: user?.['@id'],
      order: auditTrailOrderId,
      message,
      details,
    };
    this.auditTrailRepository
      .create(logRow)
      .pipe(
        mergeMap(row => this.auditTrailApi.create(row)),
        tap(row => console.log(row)),
      )
      .subscribe();
  }
}
