import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { IvmCassette } from '@models/interfaces/ivmcassette';
import { IvmCassetteApi } from '@api/ivm-cassette.api';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmTool } from '@models/interfaces/ivmtool';
import { Ivm } from '@models/interfaces/ivm';
import { SynchronizedIvmTool } from '@models/interfaces/syncableivmtool';
import { SynchronizedIvmCassette } from '@models/interfaces/syncableivmcassette';
import { IvmCassetteRepository } from '@repositories/ivm-part/ivmcassette.repository';
import { BaseIvmPartService, IvmPartServiceI } from '..';

@Injectable({
  providedIn: 'root',
})
export class IvmCassetteService extends BaseIvmPartService implements IvmPartServiceI<IvmCassette> {
  override api_url: string = '/api/ivm_cassettes';
  private ivmCassettes?: Observable<IvmCassette[]>;

  constructor(
    private ivmCassetteApi: IvmCassetteApi,
    private ivmCassetteRepository: IvmCassetteRepository,
  ) {
    super();
  }

  findAll(ivm: string): Observable<IvmCassette[]> {
    return (this.ivmCassettes ??= this.ivmCassetteApi.getAll(ivm).pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  findById(id: number, ivm: string): Observable<IvmCassette | undefined> {
    return this.findAll(ivm).pipe(
      map((ivmCassettes: IvmCassette[]) =>
        ivmCassettes.find((ivmCassette: IvmCassette) => ivmCassette['ivmPartId'] == id),
      ),
    );
  }

  create(ivmCassette: IvmCassette): Observable<IvmTool> {
    return this.ivmCassetteApi.create(ivmCassette).pipe(tap(() => (this.ivmCassettes = undefined)));
  }

  async findOrCreate(ivmPart: IvmCassette & Partial<{ cassette?: string }>, ivmObject?: Ivm) {
    const ivm = ivmObject?.['@id']?.split('/').pop() ?? '';
    let ivmCassette;
    ivmCassette = await firstValueFrom(this.findById(ivmPart.id ?? 0, ivm));

    if (ivmCassette === undefined) {
      let ivmToolObj: SynchronizedIvmCassette = {
        ivmPartId: ivmPart.id ?? 0,
        qrCode: ivmPart.qrCode,
        partName: ivmPart.partName,
        coordinateX: ivmPart.coordinateX,
        coordinateY: ivmPart.coordinateY,
        drawerNumber: ivmPart.drawerNumber,
        unit: ivmPart.unit ?? 0,
        stock: ivmPart.stock ?? 0,
        stockLocation: ivmPart.stockLocation,
        cassetteStockRequest: ivmPart.cassetteStockRequest,
        ivm: ivmObject?.['@id'] ?? '',
      };
      ivmCassette = await firstValueFrom(this.create(ivmToolObj));
    }

    return ivmCassette;
  }

  update(ivmPart: IvmCassette) {
    this.ivmCassetteRepository.update(ivmPart);
    if (ivmPart['@id']) this.ivmCassetteApi.update(ivmPart).subscribe();
  }

  delete(ivmPart: IvmCassette) {
    this.ivmCassetteRepository.delete(ivmPart);
    if (ivmPart['@id']) this.ivmCassetteApi.delete(ivmPart).subscribe();
  }
}
