import { EventEmitter, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'projects/cloud-connect/src/environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { GenericPopupComponent } from '@components/generic-popup/generic-popup.component';
import {
  IvmCommandData,
  IvmCommands,
  IvmDefinitions,
  IvmEventData,
  IvmEventsEnum,
  IvmSimulations,
} from './events.enum';
import { IvmSlot, IvmSlotSide, IvmSlots, StrictIvmSlot } from '@reducers/order-prepare';
import { ToastrService } from 'ngx-toastr';

export type WeightObservable = {
  newWeight: number;
  emptySlotWeight?: number;
  isRealWeight: boolean;
  slot: StrictIvmSlot;
};

export type IvmEmitterMessage = 'connection_open' | 'connection_is_already_open' | 'station_loaded';
@Injectable({
  providedIn: 'root',
})
export class IvmBackendService {
  socket: WebSocket | undefined;
  slotsInUse: IvmSlots = { A: null, B: null };
  reportedWeights: number[] = [];
  onNewWeight: Subject<WeightObservable> = new Subject();
  emptySlotWeight?: number = undefined; //Weight returned by the weigh unit when no item is placed
  shouldCheckRealWeight: boolean = false; //Used to determine if we should already be checking the actual weight
  isWaitingForConnectionClose = false;

  emitter: EventEmitter<IvmEmitterMessage> = new EventEmitter();

  constructor(public dialog: MatDialog, private toastService: ToastrService) {}

  init(shouldRecreateConnection = false) {
    if (this.isOpen()) {
      if (!shouldRecreateConnection) return;
    }

    if (this.isClosing()) {
      this.toastService.info('Connecting to ivm backend, Please wait ... ');
      console.log('wait for the socket connection to close');
      this.isWaitingForConnectionClose = true;
      return;
    }

    this.unsubscribe();

    this.socket = new WebSocket(`ws://${environment.ivmBackendHost}`);
    this.socket.addEventListener('close', () => this.handleClose());
    this.socket.addEventListener('error', message => this.handleError(message));
    this.socket.addEventListener('open', message => this.handleOpen(message));
    this.socket.addEventListener('message', message => this.handleMessage(message.data));
    this.isWaitingForConnectionClose = false;

    return this;
  }

  unsubscribe() {
    if (this.socket !== undefined) {
      this.socket?.close();
    }
  }

  handleError(message: Event): void {
    console.error(['Ivm connection has an error', message]);
  }

  handleClose(): void {
    console.log('ivm connection was closed');

    if (this.isWaitingForConnectionClose) {
      this.init();
    }
  }

  handleOpen(message: Event): void {
    this.emitter.emit('connection_open');
    console.log(['Ivm connection is opened', message]);
  }

  //We might expand later in future tickets, not sure yet how it will be useful
  handleMessage(message: string | IvmEventData) {
    console.log(message);
  }

  //T will usually be IvmEventData. but sometimes the backend just sends the event as message
  onEvent<T>(event: IvmEventsEnum): Observable<T> {
    return new Observable(observer => {
      this.socket?.addEventListener('message', message => {
        try {
          const data = JSON.parse(message.data);
          if (event === message.data || event === data.fc) observer.next(data);
        } catch (e) {}
      });
    });
  }

  sendEvent(event: IvmCommands, data: IvmCommandData, simulation?: IvmSimulations): void {
    console.log([event, data, simulation]);
    // TODO keeps triggering on cassette place on real ivm backend
    if (!this.isOpen()) {
      let dialog = this.dialog.open(GenericPopupComponent, {
        data: {
          title: 'Error',
          message: 'error.ivm-not-connected',
        },
      });

      dialog.afterClosed().subscribe(() => this.init());
      return;
    }

    this.socket?.send(
      JSON.stringify({
        fc: IvmDefinitions[event],
        data,
        simulation: simulation ?? 'none',
      }),
    );
  }

  closeConnection(): void {
    this.isWaitingForConnectionClose = false;
    this.socket?.close();
  }

  isOpen() {
    return this.socket && this.socket.readyState === this.socket.OPEN;
  }

  isClosing() {
    return this.socket && this.socket.readyState === this.socket.CLOSING;
  }

  listenToCanisterIdentify() {
    return this.onEvent<IvmEventData>(IvmEventsEnum.EVENT_CANISTER_IDENTIFIED);
  }

  listenToCanisterInsert() {
    return this.onEvent<IvmEventData>(IvmEventsEnum.EVENT_CANISTER_INSERTED);
  }

  listenToWeights(
    simulation: IvmSimulations,
    slot: StrictIvmSlot = 'A',
  ): Observable<WeightObservable> {
    this.reportedWeights = [];
    this.shouldCheckRealWeight = false;
    this.emptySlotWeight = undefined;
    this.startWeighing(simulation, slot);
    this.onEvent<IvmEventData>(IvmEventsEnum.EVENT_WEIGHT_REPORT).subscribe(data => {
      if (data.data.slot !== slot) {
        return;
      }

      const newWeight = data.data.weight;
      this.reportedWeights.push(newWeight);

      //We always set the first returned weight as the empty weight
      if (!this.shouldCheckRealWeight && this.emptySlotWeight === undefined) {
        this.emptySlotWeight = newWeight;
      }

      if (this.reportedWeights.length < 16) {
        return;
      }

      const lastFiveWeights = this.reportedWeights.slice(-15);

      //Only consider a weight if it came in at least 5 consecutive times
      if (!lastFiveWeights.every(weight => weight === newWeight)) {
        return;
      }

      if (!this.shouldCheckRealWeight) {
        if (this.emptySlotWeight === undefined || newWeight < this.emptySlotWeight) {
          this.emptySlotWeight = newWeight;
        }

        this.onNewWeight.next({
          newWeight,
          emptySlotWeight: this.emptySlotWeight,
          isRealWeight: this.shouldCheckRealWeight,
          slot,
        });
        return;
      }

      // even though these are "real" weights, ignore anything below 50g, just in case canister is pushed in, but taken out
      // again. The code should just wait for a canister to be placed and a weight to become stable.
      if (newWeight < 50) {
        return;
      }

      this.onNewWeight.next({
        newWeight,
        emptySlotWeight: this.emptySlotWeight,
        isRealWeight: this.shouldCheckRealWeight,
        slot,
      });
      this.stopWeighing(slot);
      return;
    });

    return this.onNewWeight;
  }

  startWeighing(simulation: IvmSimulations, slot: StrictIvmSlot = 'A') {
    const data: IvmCommandData = { slot };
    this.sendEvent('FC_START_WEIGHING', data, simulation);
  }

  stopWeighing(slot: StrictIvmSlot = 'A') {
    this.sendEvent('FC_STOP_WEIGHING', { slot });
  }

  /**
   *  In a real scenario, before scanning the canister, we want to be able to know the initial weights from the weigh unit.
   * 1. In the ngOnInit, we start weighing, at this level, all the weight return is considered as the empty slot Weight.
   * After scanning the canister, we set this.shouldCheckRealWeight to true.
   * Weights returned after it, are considered valid weights
   *
   */
  getRealWeights() {
    this.shouldCheckRealWeight = true;
  }

  stopRealWeights() {
    this.shouldCheckRealWeight = false;
  }
}
