import { IvmSlot } from '@reducers/order-prepare';

export enum AuditMessage {
  //LOGIN
  PINCODE_LOGIN_ERROR = '{{user}} entered a wrong pincode to access {{page}} page',
  PINCODE_LOGIN_SUCCESSFUL = 'accessed {{page}} page via pincode',
  TOOL_ADDED_TO_STOCK = '{{user}} added {{tool}} to the stock',
  TOOL_REMOVED_FROM_STOCK = '{{user}} removed {{tool}} from stock',
  IVM_PART_ADDED_TO_ORDER = '{{ivmPart}} {{ivmPartName}} was added to the order',
  IVM_PART_REMOVED_FROM_ORDER = '{{ivmPart}} {{ivmPartName}} was removed from order',

  //Canisters
  CANISTER_PLACED_IN_SLOT = '{{ivmPart}} {{ivmPartName}} placed in slot {{slot}}',
  CANISTER_PLACED_IN_DRAWER = '{{ivmPart}} {{ivmPartName}} back  in drawer',
  CANISTER_WEIGHT_RETURNED = '{{ivmPart}} {{ivmPartName}} weight return - {{weight}}',
  TOOL_RETURNED = '{{ivmPart}} {{ivmPartName}} returned',
  CANISTER_MARKED_AS_EMPTY = '{{ivmPart}} {{ivmPartName}} marked as Empty',

  STOCK_MUTATION_ADDED = '{{item}} {{name}} added to stock with amount {{amount}}',
  STOCK_MUTATION_ACTION = '{{ivmPart}} {{ivmPartName}} was {{stockMutationAction}} with an amount {{amount}}',
  STOCK_MUTATION_CANCELLED = '{{ivmPart}} {{ivmPartName}} cancelled with amount {{amount}}',

  IVM_PART_SCANNED = '{{ivmPart}} {{ivmPartName}} scanned',
  TOOL_SCANNED = 'Tool {{ivmPartName}} scanned',
  TOOLSET_SCANNED = 'ToolSet {{ivmPartName}} scanned',
  CANISTER_SCANNED = 'Canister {{ivmPartName}} scanned',
  ERROR_OCCURED = 'Error {{error}} occurred in {{page}} page',

  SETTINGS_RESET = 'System was reset',

  //Cassette
  CASSETTE_PLACED_IN_FILL_UNIT = 'Cassette {{cassette}} placed in Weigh Unit',
  CASSETTE_REMOVED_FROM_FILL_UNIT = 'Cassette {{cassette}} removed from Weigh Unit',

  CASSETTE_ADDED = 'Cassette {{cassette}} added in stock',
  CASSETTE_DELETED = 'Cassette {{cassette}} was deleted',
  CASSETTE_SCANNED = 'Cassette {{cassette}} scanned',
  CASSETTE_RETURNED = 'Cassette {{cassette}} returned',
  ORDER_CANCELLED = 'Order {{page}} cancelled',
  ORDER_COMPLETED = 'Order {{page}} completed',
}

export type AuditMessageType = keyof typeof AuditMessage;

export type AuditDetail = {
  tool?: string;
  canister?: string;
  slot?: IvmSlot;
  page?: 'prepare' | 'start' | 'finalize' | 'settings';
  ivmPart?: 'Tool' | 'Canister' | 'Toolset' | 'Cassette';
  ivmPartName?: string;
  stockMutationAction?: 'added' | 'used' | 're-added';
  ivmPartId?: string | null;
  amount?: number;
  cassette?: string;
};

export type WithRequiredKey<Key extends keyof AuditDetail> = AuditDetail & {
  [Property in Key]-?: AuditDetail[Property];
};

export type FromAction<Key extends AuditMessageType> = Key;

export type AuditTrailRequiredDetail =
  | ({
      action: FromAction<
        | 'TOOL_ADDED_TO_STOCK'
        | 'TOOL_REMOVED_FROM_STOCK'
        | 'CANISTER_PLACED_IN_DRAWER'
        | 'IVM_PART_SCANNED'
        | 'CANISTER_MARKED_AS_EMPTY'
        | 'IVM_PART_ADDED_TO_ORDER'
        | 'IVM_PART_REMOVED_FROM_ORDER'
        | 'TOOL_RETURNED'
        | 'TOOL_SCANNED'
        | 'TOOLSET_SCANNED'
        | 'CANISTER_SCANNED'
        | 'CANISTER_WEIGHT_RETURNED'
      >;
    } & WithRequiredKey<'ivmPartName' | 'ivmPart' | 'ivmPartId'>)
  | ({ action: FromAction<'CANISTER_PLACED_IN_SLOT'> } & WithRequiredKey<
      'ivmPartName' | 'ivmPart' | 'ivmPartId' | 'slot'
    >)
  | ({ action: FromAction<'STOCK_MUTATION_ADDED' | 'STOCK_MUTATION_CANCELLED'> } & WithRequiredKey<
      'ivmPart' | 'ivmPartName' | 'amount'
    >)
  | ({
      action: FromAction<
        'PINCODE_LOGIN_SUCCESSFUL' | 'ORDER_CANCELLED' | 'ERROR_OCCURED' | 'ORDER_COMPLETED'
      >;
    } & WithRequiredKey<'page'>)
  | ({
      action: FromAction<
        | 'CASSETTE_ADDED'
        | 'CASSETTE_DELETED'
        | 'CASSETTE_PLACED_IN_FILL_UNIT'
        | 'CASSETTE_SCANNED'
        | 'CASSETTE_REMOVED_FROM_FILL_UNIT'
        | 'CASSETTE_RETURNED'
      >;
    } & WithRequiredKey<'cassette'>)
  | { action: FromAction<'SETTINGS_RESET'> }
  | ({ action: FromAction<'STOCK_MUTATION_ACTION'> } & WithRequiredKey<
      'amount' | 'stockMutationAction' | 'ivmPart' | 'ivmPartId' | 'ivmPartName'
    >);

//Allow details to have more than the required properties
export type AuditTrailDetail = AuditTrailRequiredDetail &
  Record<string, string | undefined | null | number>;
