import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, tap } from 'rxjs';
import { IvmCassette } from '@models/interfaces/ivmcassette';
import { StockRequestCassette } from '@models/interfaces/stockrequestcassette';
import { IvmPart } from '@models/interfaces/ivmpart';
import { BaseIvmPartRepository } from '.';

@Injectable({
  providedIn: 'root',
})
export class IvmCassetteRepository extends BaseIvmPartRepository<IvmCassette> {
  table = 'ivmCassette';

  add(
    qrCode: string,
    cassette: StockRequestCassette,
    drawer: IvmPart,
    orderNumber: string,
  ): Observable<IvmCassette> {
    if (!drawer.coordinateX || !drawer.coordinateY || !drawer.drawerNumber) {
      throw new Error('Invalid coordinates and/or drawer: ' + JSON.stringify(drawer));
    }

    const data: IvmCassette = {
      qrCode: qrCode,
      partName: orderNumber,
      cassetteStockRequest: cassette['@id'],
      coordinateX: drawer.coordinateX,
      coordinateY: drawer.coordinateY,
      drawerNumber: drawer.drawerNumber,
      unit: drawer.unit,
      stock: 1,
      stockLocation: drawer.stockLocation,
      ...(drawer['ivmPartId'] && { id: drawer['ivmPartId'] }),
      ...(drawer['@id'] && { '@id': drawer['@id'] }),
    };
    return this.dbService.add('ivmCassette', data);
  }

  findByCassette(cassette: string): Observable<IvmCassette[]> {
    const uuid = '/api/stock_request_cassettes/' + cassette.split('/').pop();
    return this.dbService.getAllByIndex(
      'ivmCassette',
      'cassetteStockRequest',
      IDBKeyRange.only(uuid),
    );
  }
}
