import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, of, switchMap, tap } from 'rxjs';
import { IvmToolSet } from '@models/interfaces/ivmtoolset';
import { ToolSet } from '@models/interfaces/toolset';
import { IvmPart } from '@models/interfaces/ivmpart';
import { BaseIvmPartRepository } from '.';

@Injectable({
  providedIn: 'root',
})
export class IvmToolSetRepository extends BaseIvmPartRepository<IvmToolSet> {
  table = 'ivmToolSet';
  add(code: string, toolSet: ToolSet, drawer: IvmPart): Observable<IvmToolSet> {
    if (!drawer.coordinateX || !drawer.coordinateY || !drawer.drawerNumber) {
      throw new Error('Invalid coordinates and/or drawer: ' + JSON.stringify(drawer));
    }

    const data: IvmToolSet = {
      qrCode: code, // unique id
      partName: toolSet.toolingSetId,
      toolSet: toolSet['@id'],
      coordinateX: drawer.coordinateX,
      coordinateY: drawer.coordinateY,
      drawerNumber: drawer.drawerNumber,
      unit: drawer.unit,
      stock: 1,
      stockLocation: drawer.stockLocation,
      ...(drawer['ivmPartId'] && { id: drawer['ivmPartId'] }),
      ...(drawer['@id'] && { '@id': drawer['@id'] }),
    };
    return this.dbService.add('ivmToolSet', data);
  }

  findByToolSet(tool: string): Observable<IvmToolSet[]> {
    const uuid = '/api/tool_sets/' + tool.split('/').pop();
    return this.dbService.getAllByIndex('ivmToolSet', 'toolSet', IDBKeyRange.only(uuid));
  }

  findByCustomToolSet(toolSet: string): Observable<IvmToolSet[]> {
    return this.dbService.getAllByIndex('ivmToolSet', 'toolSet', IDBKeyRange.only(toolSet));
  }

  findByToolSetOrCustom(toolSetId: string) {
    return this.findByToolSet(toolSetId).pipe(
      switchMap(toolSets => (toolSets.length ? of(toolSets) : this.findByCustomToolSet(toolSetId))),
    );
  }
}
