import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { IvmFastener } from '@models/interfaces/ivmfastener';
import { IvmFastenerApi } from '@api/ivm-fastener.api';
import { SynchronizedIvmFastener } from '@models/interfaces/syncableivmfastener';
import { Ivm } from '@models/interfaces/ivm';
import { IvmFastenerRepository } from '@repositories/ivm-part/ivmfastener.repository';
import { BaseIvmPartService, IvmPartServiceI } from '..';

@Injectable({
  providedIn: 'root',
})
export class IvmFastenerService extends BaseIvmPartService implements IvmPartServiceI<IvmFastener> {
  api_url = '/api/ivm_fasteners';
  private ivmFasteners?: Observable<IvmFastener[]>;

  constructor(
    private ivmFastenerApi: IvmFastenerApi,
    private ivmFastenerRepository: IvmFastenerRepository,
  ) {
    super();
  }

  findAll(ivm: string): Observable<IvmFastener[]> {
    return (this.ivmFasteners ??= this.ivmFastenerApi.getAll(ivm).pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  findById(id: number | undefined, ivm: string): Observable<IvmFastener | undefined> {
    return this.findAll(ivm).pipe(
      map((ivmFasteners: IvmFastener[]) =>
        ivmFasteners.find((ivmFastener: IvmFastener) => ivmFastener['ivmPartId'] == id),
      ),
    );
  }

  create(ivmFastener: SynchronizedIvmFastener): Observable<IvmFastener> {
    return this.ivmFastenerApi.create(ivmFastener).pipe(tap(() => (this.ivmFasteners = undefined)));
  }

  async findOrCreate(ivmFastener: IvmFastener, ivmObject?: Ivm) {
    const ivm = ivmObject?.['@id']?.split('/').pop() ?? '';
    const ivmPartId = ivmFastener.id;
    let ivmFastenerBackend;

    ivmFastenerBackend = await lastValueFrom(this.findById(ivmPartId, ivm));

    if (ivmFastenerBackend === undefined) {
      let ivmFastenerObj: SynchronizedIvmFastener = {
        ivmPartId: ivmPartId,
        qrCode: ivmFastener.qrCode,
        partName: ivmFastener.partName,
        coordinateX: ivmFastener.coordinateX,
        coordinateY: ivmFastener.coordinateY,
        drawerNumber: ivmFastener.drawerNumber,
        unit: ivmFastener.unit ?? 0,
        stock: ivmFastener.stock ?? 0,
        stockLocation: ivmFastener.stockLocation ?? 'IVM',
        fastener: ivmFastener.fastener,
        ivm: ivmObject?.['@id'] ?? '',
        emptyWeight: ivmFastener.emptyWeight,
      };
      ivmFastenerBackend = await lastValueFrom(this.create(ivmFastenerObj));
    }

    return ivmFastenerBackend;
  }

  update(ivmPart: IvmFastener) {
    this.ivmFastenerRepository.update(ivmPart);
    if (ivmPart['@id']) this.ivmFastenerApi.update(ivmPart).subscribe();
  }

  delete(ivmPart: IvmFastener) {
    this.ivmFastenerRepository.delete(ivmPart);
    if (ivmPart['@id']) this.ivmFastenerApi.delete(ivmPart).subscribe();
  }
}
