import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { StockRequest, StockRequestStatus } from '@models/interfaces/stockrequest';
import { StockRequestApi } from '@api/stockrequest.api';
import { HydraResponse } from '@models/response/HydraResponse';
import { StockRequestFastener } from '@models/interfaces/stockrequestfastener';

@Injectable({
  providedIn: 'root',
})
export class StockRequestService {
  private stockRequests$!: Observable<StockRequest[]> | undefined;

  constructor(private stockRequestApi: StockRequestApi) {}

  findAll(): Observable<StockRequest[]> {
    return (this.stockRequests$ ??= this.stockRequestApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  findByTools(tool: string): Observable<StockRequest[]> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.filter(
          (stockRequest: StockRequest) =>
            stockRequest['@type'] === 'StockRequestTool' && stockRequest.tool === tool, // && stockRequest.status === 'new',
        ),
      ),
    );
  }

  findByToolSets(toolSet: string): Observable<StockRequest[]> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.filter(
          (stockRequest: StockRequest) =>
            stockRequest['@type'] === 'StockRequestToolSet' && stockRequest.toolSet === toolSet, // && stockRequest.status === 'new',
        ),
      ),
    );
  }

  findByFasteners(fastener: string): Observable<StockRequest[]> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.filter(
          (stockRequest: StockRequest) =>
            stockRequest['@type'] === 'StockRequestFastener' && stockRequest.fastener === fastener, // && stockRequest.status === 'new',
        ),
      ),
    );
  }

  findCanisters(): Observable<StockRequest[]> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.filter(
          (stockRequest: StockRequest) => stockRequest['@type'] === 'StockRequestFastener',
        ),
      ),
    );
  }

  find(uuid: string): Observable<StockRequest | undefined> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.find(
          (stockRequest: StockRequest) => stockRequest['@id']?.includes(uuid), // && stockRequest['status'] === 'new',
        ),
      ),
    );
  }

  findPreFilled(): Observable<StockRequest[]> {
    return this.findAll().pipe(
      map((response: StockRequest[]) =>
        response.filter(
          (stockRequest: StockRequest) => stockRequest['@type'] === 'StockRequestCassette', // && stockRequest['status'] === 'new',
        ),
      ),
    );
  }

  updateStatus(stockRequestOld: StockRequest): Observable<HydraResponse<StockRequest>> {
    // Here we update the status of stockRequest as it is added to IVM now
    let update: Partial<StockRequest> = {
      updatedAt: new Date(),
      status: StockRequestStatus.completed,
    };
    const updatedStockRequest: StockRequest = { ...stockRequestOld, ...update };
    return this.stockRequestApi.updateStatus(updatedStockRequest);
  }

  clearCache(): void {
    this.stockRequests$ = undefined;
  }

  createStockRequestFastener(stockRequest: StockRequest): Observable<StockRequestFastener> {
    return this.stockRequestApi.createStockRequestFastener(stockRequest);
  }
}
