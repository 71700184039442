import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { FastenerWeight } from '@models/interfaces/fastenerweight';
import { FastenerWeightApi } from '@api/fastener-weight.api';

@Injectable({
  providedIn: 'root',
})
export class FastenerWeightService {
  private fastenerWeights$!: Observable<FastenerWeight[]>;

  constructor(private fastenerWeightApi: FastenerWeightApi) {}

  findAll(): Observable<FastenerWeight[]> {
    return (this.fastenerWeights$ ??= this.fastenerWeightApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  find(id: string): Observable<FastenerWeight | undefined> {
    return this.findAll().pipe(
      map((response: FastenerWeight[]) =>
        response.find(
          (fastener: FastenerWeight) =>
            fastener['@id'] === id || fastener['@id']?.split('/').pop() === id,
        ),
      ),
    );
  }

  findByFastener(fastener: string): Observable<FastenerWeight | undefined> {
    return this.findAll().pipe(
      map(fastenerWeights =>
        fastenerWeights.find(fastenerWeight => fastenerWeight.fastener['@id'] === fastener),
      ),
    );
  }
}
