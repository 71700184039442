import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, Observable } from 'rxjs';
import { Cassette } from '@models/interfaces/cassette';
import { CassetteType } from '@models/utils/cassette';

@Injectable({
  providedIn: 'root',
})
export class CassetteRepositoryService {
  public totalCassette = [];

  constructor(private dbService: NgxIndexedDBService) {}

  async getAllCassette(): Promise<Cassette[]> {
    return await firstValueFrom(this.dbService.getAll('cassette'));
  }

  async hasEmptySlots(): Promise<boolean> {
    this.totalCassette = await firstValueFrom(this.dbService.getAll('cassette'));
    return this.totalCassette.length !== 5;
  }

  /**
   * This will get the first empty cassette available
   */
  async getEmptyCassette(type: CassetteType = 'FILL_UNIT'): Promise<Cassette | undefined> {
    const emptyCassettes = await this.getEmptyCassettes();
    return emptyCassettes.filter(cassette => cassette.type === type).shift();
  }

  /**
   * This will get all the empty cassettes available
   */
  async getEmptyCassettes(): Promise<Cassette[]> {
    return await firstValueFrom(
      this.dbService.getAllByIndex('cassette', 'empty', IDBKeyRange.only(1)),
    );
  }

  async getCassetteForOrder(orderNumber: string): Promise<Cassette | undefined> {
    return await firstValueFrom(this.dbService.getByIndex('cassette', 'orderNumber', orderNumber));
  }

  async findByQrCode(code: string): Promise<Cassette | undefined> {
    return await firstValueFrom(this.dbService.getByIndex('cassette', 'qrCode', code));
  }

  async addToFirstEmptySlot(
    code: string,
    slot: number,
    type: CassetteType = 'FILL_UNIT',
  ): Promise<Cassette> {
    slot = this.calculateSlot();
    return await this.addCassette(code, 0, slot, type);
  }

  async addCassette(
    code: string,
    unit: number,
    slot: number,
    type: CassetteType = 'FILL_UNIT',
  ): Promise<Cassette> {
    return await firstValueFrom(
      this.dbService.add('cassette', {
        qrCode: code,
        name: code.split('-')[0].substring(1),
        prepared: `no`,
        unit: unit,
        slot: slot,
        addedAt: new Date(),
        empty: 1,
        type,
        emptyWeight: Number(code.split('-').pop()),
      }),
    );
  }

  async updateCassetteState(cassette: Cassette, empty: number): Promise<Cassette[]> {
    return await firstValueFrom(this.dbService.update('cassette', { ...cassette, empty }));
  }

  async updateCassette(cassette: Cassette, data: Partial<Cassette>): Promise<Cassette[]> {
    return await firstValueFrom(this.dbService.update('cassette', { ...cassette, ...data }));
  }

  async deleteCassette(cassette: Cassette): Promise<boolean> {
    return await firstValueFrom(this.dbService.deleteByKey('cassette', cassette.id));
  }

  clearCassette(): Observable<boolean> {
    return this.dbService.clear('cassette');
  }

  private calculateSlot(): number {
    const allSlot: Array<number> = [1, 2, 3, 4, 5];
    const filledSlots: Array<number> = this.totalCassette.map(result => result['slot']);
    return allSlot.filter(item => !filledSlots.includes(item))[0];
  }
}
