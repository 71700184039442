import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { Order } from '@models/interfaces/order';
import { toIsoString } from '@models/utils/sync';

@Injectable({
  providedIn: 'root',
})
export class OrderApi {
  constructor(private http: HttpClient) {}

  getAll(): Observable<HydraResponse<Order>> {
    return this.http.get<HydraResponse<Order>>(`${environment.apiBaseUrl}/api/orders`);
  }

  updateStatus(order: Order): Observable<HydraResponse<Order>> {
    return this.http.put<HydraResponse<Order>>(
      `${environment.apiBaseUrl}${order['@id']}`,
      {
        status: order.status,
        updatedAt: toIsoString(order.updatedAt || new Date()),
        startDateTime: toIsoString(order.startDateTime), //do not send the startDateTime to api if not available
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  update(order: Order, data: Pick<Order, 'batch'>): Observable<HydraResponse<Order>> {
    return this.http.put<HydraResponse<Order>>(`${environment.apiBaseUrl}${order['@id']}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
