import { Action, createReducer, on } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import {
  StateToggleItem,
  StateToggleItemWithSteps,
  StateToggleStatus,
} from '@interfaces/utils/state-toggle';
import { Cassette } from '@models/interfaces/cassette';
import { PrepareOrderActions } from './actions';
import { CanisterDetailsInterface } from './properties';
import { IvmSlot, IvmSlots, PrepareOrderSteps } from './enum';

export type CanisterStepType = StateToggleItemWithSteps & {
  details: CanisterDetailsInterface;
  slot: IvmSlot;
  isFilling?: boolean;
  isLoaded?: boolean;
  isEmpty?: boolean;
  station?: number;
  isStockMutationAdded?: boolean;
};

export interface PrepareOrderState {
  orderNumber?: string;
  cassettes: {
    title: string;
    isOpen?: boolean;
    steps: StateToggleItem[];
    status: StateToggleStatus;
    cassette?: Cassette;
  };
  canisters: {
    isOpen?: boolean;
    status: StateToggleStatus;
    list: CanisterStepType[];
  };
  final: { status: StateToggleStatus; steps: StateToggleItem[]; isOpen: boolean };
  title?: string;
  currentStep: PrepareOrderSteps;
  currentCanister?: CanisterStepType;
  slots: IvmSlots;
  isCompleted?: boolean;
}

const initialState: PrepareOrderState = {
  cassettes: {
    title: 'Cassette 1',
    steps: [],
    status: 'PROCESSING',
    isOpen: true,
  },
  canisters: {
    status: 'PENDING',
    list: [],
  },
  final: {
    status: 'PENDING',
    isOpen: false,
    steps: [
      {
        id: PrepareOrderSteps.FinalizeTakeOutOfFillUnit,
        title: 'title.take_out_of_fill_unit',
        status: 'PENDING',
      },
      {
        id: PrepareOrderSteps.FinalizePreparation,
        title: 'title.finalize_preparation',
        status: 'PENDING',
      },
    ],
  },
  title: 'Place the Cassette in the Unit',
  currentStep: PrepareOrderSteps.CassetteScanQrCode,
  slots: {
    A: null,
    B: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(PrepareOrderActions.initialize, (state, properties) => {
    return {
      ...initialState,
      orderNumber: properties.orderNumber,
    };
  }),
  on(PrepareOrderActions.reset, state => {
    return initialState;
  }),
  mutableOn(PrepareOrderActions.addCassette, (state, properties) => {
    state.cassettes = {
      ...state.cassettes,
      cassette: properties.cassette,
      title: `${properties.cassette.name}, U${properties.cassette.unit}`,
      steps: [
        {
          id: PrepareOrderSteps.CassetteScanQrCode,
          title: 'Scan QR code',
          status: 'PROCESSING',
          type: 'ITEM',
        },
        {
          id: PrepareOrderSteps.CassettePlaceInFillUnit,
          title: 'In Fill Unit',
          status: 'PENDING',
          type: 'ITEM',
        },
      ],
    };
  }),
  mutableOn(PrepareOrderActions.addCanisters, (state, properties) => {
    if (!properties.canisters) {
      return;
    }
    const canisters: CanisterStepType[] = properties.canisters?.map((canister, index) => {
      return {
        id: canister.id,
        title: canister.title,
        subtitle: canister.subtitle,
        details: canister.details,
        isFilling: false,
        isLoaded: false,
        isStockMutationAdded: false,
        slot: null,
        station: canister.details.station ?? index + 1,
        status: 'PENDING',
        steps: [
          {
            id: PrepareOrderSteps.CanisterScanQrCode,
            title: 'Scan QR Code',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterPlaceInFillUnit,
            title: 'In Fill Unit',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterWaitForFilling,
            title: 'Waiting for Filling',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterBackInDrawer,
            title: 'Back In Drawer',
            status: 'PENDING',
          },
        ],
      };
    });

    state.canisters.status = 'PENDING';
    state.canisters.list = canisters;
  }),

  mutableOn(PrepareOrderActions.pushCanisters, (state, properties) => {
    if (!properties.canisters) {
      return;
    }
    const canisters: CanisterStepType[] = properties.canisters?.map(canister => {
      return {
        id: canister.id,
        title: canister.title,
        subtitle: canister.subtitle,
        details: canister.details,
        isFilling: false,
        slot: null,
        station: properties.station,
        status: 'PENDING',
        steps: [
          {
            id: PrepareOrderSteps.CanisterScanQrCode,
            title: 'Scan QR Code',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterPlaceInFillUnit,
            title: 'In Fill Unit',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterWaitForFilling,
            title: 'Waiting for Filling',
            status: 'PENDING',
          },
          {
            id: PrepareOrderSteps.CanisterBackInDrawer,
            title: 'Back In Drawer',
            status: 'PENDING',
          },
        ],
      };
    });

    canisters.map(cn => {
      state.canisters.list.splice(properties.canisterIndex + 1, 0, cn);
    });
  }),

  /**
   * When we scan the cassette, it should automatically start the next step (Place in Fill Unit)
   */
  mutableOn(PrepareOrderActions.cassetteScanQrCode, (state, properties) => {
    state.cassettes.steps[0].status = properties.status;

    if (properties.status === 'COMPLETED') {
      state.cassettes.steps[1].status = 'PROCESSING';
      state.currentStep = PrepareOrderSteps.CassettePlaceInFillUnit;
    }
  }),
  /**
   * After placing in the fill unit, it automatically starts
   */
  mutableOn(PrepareOrderActions.cassettePlaceInFillUnit, (state, properties) => {
    state.cassettes.steps[1].status = properties.status;

    if (properties.status === 'COMPLETED' && state.canisters.list.length) {
      state.cassettes.status = 'COMPLETED';
      state.cassettes.isOpen = false;

      state.canisters.isOpen = true;
      state.canisters.status = 'PROCESSING';
      state.canisters.list[0].status = 'PROCESSING';
      state.canisters.list[0].steps[0].status = 'PROCESSING';
      state.canisters.list[0].isOpen = true;
      state.currentStep = PrepareOrderSteps.CanisterScanQrCode;
      state.currentCanister = state.canisters.list[0];
    }
  }),
  mutableOn(PrepareOrderActions.canisterScanQrCodeStart, (state, properties) => {
    state.canisters.list.map(ln => (ln.isOpen = false)); //close
    state.canisters.isOpen = true;
    state.canisters.status = 'PROCESSING';
    state.canisters.list[properties.canisterIndex].status = 'PROCESSING';
    state.canisters.list[properties.canisterIndex].steps[0].status = 'PROCESSING';
    state.canisters.list[properties.canisterIndex].isOpen = true;
    state.currentStep = PrepareOrderSteps.CanisterScanQrCode;
    state.currentCanister = state.canisters.list[properties.canisterIndex];
  }),
  mutableOn(PrepareOrderActions.canisterScanQrCodeCompleted, (state, properties) => {
    state.canisters.list[properties.canisterIndex].slot = properties.slot;
    state.currentCanister = state.canisters.list[properties.canisterIndex];
    state.canisters.list[properties.canisterIndex].steps[0].status = 'COMPLETED';
    state.slots[properties.slot] = state.currentCanister.id;
  }),
  mutableOn(PrepareOrderActions.canisterPlaceInFillUnitStart, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[1].status = 'PROCESSING';
    state.currentStep = PrepareOrderSteps.CanisterPlaceInFillUnit;
  }),
  mutableOn(PrepareOrderActions.canisterPlaceInFillUnitCompleted, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[1].status = 'COMPLETED';
  }),
  mutableOn(PrepareOrderActions.changeCurrentCanister, (state, properties) => {
    state.currentCanister = state.canisters.list[properties.canisterIndex];
    state.currentStep = state.currentCanister.steps.find(step => step.status === 'PROCESSING')!
      .id as PrepareOrderSteps;
    state.canisters.list.map(ln => (ln.isOpen = false));
    state.canisters.list[properties.canisterIndex].isOpen = true;
  }),
  mutableOn(PrepareOrderActions.canisterWaitForFillingStart, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[2].status = 'PROCESSING';
    state.currentStep = PrepareOrderSteps.CanisterWaitForFilling;
  }),
  mutableOn(PrepareOrderActions.canisterRestartStep3, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[2].status = 'PENDING';
    state.canisters.list[properties.canisterIndex].isFilling = false;
  }),
  mutableOn(PrepareOrderActions.canisterWaitForFillingCompleted, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[2].status = 'COMPLETED';
    state.canisters.list[properties.canisterIndex].isFilling = false;
  }),
  mutableOn(PrepareOrderActions.canisterBackInDrawerStart, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[3].status = 'PROCESSING';
    if (
      state.canisters.list[properties.canisterIndex].id === state.currentCanister?.id ||
      properties.isDynamic
    ) {
      state.canisters.list.map(ln => (ln.isOpen = false));
      state.canisters.list[properties.canisterIndex].isOpen = true;
      state.currentCanister = state.canisters.list[properties.canisterIndex];
      state.currentStep = PrepareOrderSteps.CanisterBackInDrawer;
    }

    const currentCanister = state.canisters.list[properties.canisterIndex];
    const weightInMg = state.canisters.list[properties.canisterIndex].details.weight;

    const newTotalQuantity =
      state.canisters.list[properties.canisterIndex].details.totalQuantity -
      currentCanister.details.quantity;

    state.canisters.list
      .filter((cn, index) => {
        return (
          index > properties.canisterIndex &&
          cn.steps[2].status === 'PENDING' &&
          cn.details.ivmFastener.partName === currentCanister.details.ivmFastener.partName
        );
      })
      .map(cn => {
        cn.details.totalQuantity = newTotalQuantity;
        cn.details.totalWeight = Math.ceil((weightInMg * newTotalQuantity) / 1000);
      });

    //TODO: Delete the remaining canisters with the same part name if we have reached the total quantity.
  }),
  mutableOn(PrepareOrderActions.addStockMutationForCanisterStart, (state, properties) => {
    state.canisters.list[properties.canisterIndex].isLoaded = true;
  }),
  mutableOn(PrepareOrderActions.canisterBackInDrawerCompleted, (state, properties) => {
    state.canisters.list[properties.canisterIndex].status = 'COMPLETED';
    state.canisters.list[properties.canisterIndex].steps[3].status = 'COMPLETED';
    state.canisters.list[properties.canisterIndex].isOpen = false;

    //we empty the slot used after removing the canister from ivm
    const slotUsed = state.canisters.list[properties.canisterIndex].slot;
    if (slotUsed) {
      state.slots[slotUsed] = null;
      state.canisters.list[properties.canisterIndex].slot = null;
    }
  }),
  //Update the current weight of a particular canister
  mutableOn(PrepareOrderActions.updateWeightValue, (state, properties) => {
    state.canisters.list.map(canister => {
      if (canister.id == properties.canister && properties.weightValue) {
        //This is to know if the canister is actually filling
        canister.isFilling = true;
        canister.details.currentWeight = properties.weightValue;
      }
    });
  }),
  mutableOn(PrepareOrderActions.markAsEmpty, (state, properties) => {
    state.canisters.list[properties.canisterIndex].isEmpty = true;
    state.canisters.list[properties.canisterIndex].subtitle = `${
      state.canisters.list[properties.canisterIndex].details.ivmFastener.partName
    } ${properties.quantityUsed}X`;
    state.canisters.list[properties.canisterIndex].details.quantity = properties.quantityUsed;
  }),
  mutableOn(PrepareOrderActions.updateFastenerQuantityInAFastener, (state, properties) => {
    const weightInMg = state.canisters.list[properties.canisterIndex].details.weight;
    const newQuantity =
      state.canisters.list[properties.canisterIndex].details.quantity + properties.quantityToUpdate;

    state.canisters.list[properties.canisterIndex].subtitle = `${
      state.canisters.list[properties.canisterIndex].details.ivmFastener.partName
    } ${newQuantity}X`;
    state.canisters.list[properties.canisterIndex].details.quantity = newQuantity;
    state.canisters.list[properties.canisterIndex].details.targetWeight = Math.ceil(
      (weightInMg * newQuantity) / 1000,
    );
  }),
  mutableOn(PrepareOrderActions.addStockMutationForCanisterSuccess, (state, properties) => {
    state.canisters.list[properties.canisterIndex].isStockMutationAdded = true;
    state.canisters.list[properties.canisterIndex].isEmpty = properties.isEmpty;
  }),
  mutableOn(PrepareOrderActions.retryFilling, (state, properties) => {
    state.canisters.list[properties.canisterIndex].steps[2].status = 'PENDING';
    state.canisters.list[properties.canisterIndex].steps[1].status = 'PROCESSING';
    if (state.currentCanister?.id === state.canisters.list[properties.canisterIndex]?.id) {
      state.currentStep = PrepareOrderSteps.CanisterPlaceInFillUnit; //switch back to previous step only if error happens on current canister
    }
    return;
  }),
  mutableOn(PrepareOrderActions.emergencyStop, (state, properties) => {
    state.canisters.list.map(canister => {
      if (canister.status == 'PROCESSING') {
        if (canister.steps[2].status === 'PROCESSING') {
          canister.steps[2].status = 'PENDING';
          canister.steps[1].status = 'PROCESSING';
          if (state.currentCanister?.id === canister.id) {
            state.currentStep = PrepareOrderSteps.CanisterPlaceInFillUnit; //switch back to previous step only if error happens on current canister
          }
        }
      }
    });
  }),
  mutableOn(PrepareOrderActions.finalizeStart, (state, properties) => {
    state.canisters.status = 'COMPLETED';
    state.canisters.isOpen = false;

    state.final.isOpen = true;
    state.final.status = 'PROCESSING';
    state.final.steps[0].status = 'PROCESSING';
    state.currentStep = PrepareOrderSteps.FinalizeTakeOutOfFillUnit;
    state.isCompleted = true;
  }),
  mutableOn(PrepareOrderActions.finalizeTakeOutOfFillUnit, state => {
    state.final.steps[0].status = 'COMPLETED';
    state.final.steps[1].status = 'PROCESSING';
    state.currentStep = PrepareOrderSteps.FinalizePreparation;
  }),
);

export const PrepareOrderReducer = (
  state: PrepareOrderState | undefined,
  action: Action,
): PrepareOrderState => {
  return reducer(state, action);
};
