import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmCassette } from '@models/interfaces/ivmcassette';

@Injectable({
  providedIn: 'root',
})
export class IvmCassetteApi {
  constructor(private http: HttpClient) {}

  getAll(ivm: string | undefined): Observable<HydraResponse<IvmCassette>> {
    return this.http.get<HydraResponse<IvmCassette>>(
      `${environment.apiBaseUrl}/api/ivm_cassettes?ivm=` + ivm,
    );
  }

  create(ivmCassette: IvmCassette): Observable<IvmCassette> {
    return this.http.post<IvmCassette>(
      `${environment.apiBaseUrl}/api/ivm_cassettes`,
      {
        ...ivmCassette,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  update(ivmCassette: IvmCassette): Observable<IvmCassette> {
    return this.http.put<IvmCassette>(
      `${environment.apiBaseUrl}${ivmCassette['@id']}`,
      {
        stock: ivmCassette.stock,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  delete(ivmCassette: IvmCassette): Observable<IvmCassette> {
    return this.http.delete<IvmCassette>(`${environment.apiBaseUrl}${ivmCassette['@id']}`, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
