import { Injectable } from '@angular/core';
import { DBConfig } from 'ngx-indexed-db';

type MigrationFactoryInterface = {
  [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void;
};
// Ahead of time compiles requires an exported function for factories
export function migrationFactory(): MigrationFactoryInterface {
  // The animal table was added with version 2 but none of the existing tables or data needed
  // to be modified so a migrator for that version is not included.
  return {
    3: (db, transaction) => {
      const table = transaction.objectStore('stockMutation');
      table.createIndex('action', 'action', { unique: false });
    },
    //We added ivmCassette table in version 4
    5: (db, transaction) => {
      const table = transaction.objectStore('cassette');
      table.createIndex('type', 'type', { unique: false });
    },
    //Add partName index to ivm tables
    6: (db, transaction) => {
      const tables = ['ivmTool', 'ivmToolSet', 'ivmCassette', 'ivmFastener'];
      tables.map(table => {
        const ivmTable = transaction.objectStore(table);
        ivmTable.createIndex('partName', 'partName', { unique: false });
      });
    },
    //Added Request table in version 7
    //Added CustomTool & CustomToolSet table in version 8
    8: (db, transaction) => {
      const tables = ['stockMutation'];
      tables.map(table => {
        const ivmTable = transaction.objectStore(table);
        ivmTable.createIndex('partName', 'partName', { unique: false });
      });
    },
    //Added CustomTool & CustomToolSet table in version 8
    //Added Audit Trail table in version 9
    10: (db, transaction) => {
      const ivmTable = transaction.objectStore('stockMutation');
      ivmTable.createIndex('qrCode', 'qrCode', { unique: false });
      ivmTable.createIndex('coordinateX', 'coordinateX', { unique: false });
      ivmTable.createIndex('coordinateY', 'coordinateY', { unique: false });
      ivmTable.createIndex('drawerNumber', 'drawerNumber', { unique: false });
      ivmTable.createIndex('unit', 'unit', { unique: false });
    },
    11: (db, transaction) => {
      const tables = ['ivmTool', 'ivmToolSet', 'ivmCassette', 'ivmFastener', 'stockMutation'];
      tables.map(table => {
        const ivmTable = transaction.objectStore(table);
        ivmTable.createIndex('stockLocation', 'stockLocation', { unique: false });
      });
    },
    12: (db, transaction) => {
      const ivmTable = transaction.objectStore('ivmCassette');
      ivmTable.createIndex('cassetteStockRequest', 'cassetteStockRequest', { unique: false });
    },
  };
}

/**
 * 1. When we need to add new table in the database,
 *    - we increment the version number e.g version: 3
 *    - we add an object for the table definition in the objectStoresMeta, e.g
 *       {
          store: 'tableName',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'column1', keypath: 'column1', options: { unique: false } },
            { name: 'column2', keypath: 'column2', options: { unique: false } },
            { name: 'column3', keypath: 'column3', options: { unique: false } },
          ],
        },
 *
 *
 * 2. When we need to update a column in an existing table,
 *    - we increment the version number e.g version: 4
 *    - we specify in function migrationFactory() which column was added in which table
 * e.g
 *  4: (db, transaction) => {
 *      const table = transaction.objectStore('table_name');
 *      table.createIndex('column', 'column', {unique:false});
 *    }
 */

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  databaseSetup(): DBConfig {
    return {
      name: 'ivm-cloud-connect-db',
      version: 12,
      objectStoresMeta: [
        {
          store: 'stockMutation',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'ivmPart', keypath: 'ivmPart', options: { unique: false } },
            { name: 'previous', keypath: 'previous', options: { unique: false } },
            { name: 'quantity', keypath: 'quantity', options: { unique: false } },
            { name: 'stockBefore', keypath: 'stockBefore', options: { unique: false } },
            { name: 'stockAfter', keypath: 'stockAfter', options: { unique: false } },
            { name: 'synced', keypath: 'synced', options: { unique: false } },
            { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
            { name: 'orderNumber', keypath: 'orderNumber', options: { unique: false } }, //We add orderNumber to identify which order updated the mutation
          ],
        },
        {
          store: 'cassette',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'qrCode', keypath: 'qrCode', options: { unique: true } },
            { name: 'prepared', keypath: 'prepared', options: { unique: false } },
            { name: 'name', keypath: 'name', options: { unique: true } },
            { name: 'unit', keypath: 'unit', options: { unique: false } },
            { name: 'slot', keypath: 'slot', options: { unique: false } },
            { name: 'addedAt', keypath: 'addedAt', options: { unique: false } },
            { name: 'empty', keypath: 'empty', options: { unique: false } },
            { name: 'canisters', keypath: 'canisters', options: { unique: false } },
            { name: 'orderNumber', keypath: 'orderNumber', options: { unique: false } },
            { name: 'emptyWeight', keypath: 'emptyWeight', options: { unique: false } },
          ],
        },
        {
          store: 'ivmTool',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'qrCode', keypath: 'qrCode', options: { unique: false } },
            { name: 'tool', keypath: 'tool', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'stock', keypath: 'stock', options: { unique: false } },
            {
              name: 'lastIvmStockMutation',
              keypath: 'lastIvmStockMutation',
              options: { unique: false },
            },
          ],
        },
        {
          store: 'ivmToolSet',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'qrCode', keypath: 'qrCode', options: { unique: false } },
            { name: 'toolSet', keypath: 'toolSet', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'stock', keypath: 'stock', options: { unique: false } },
            {
              name: 'lastIvmStockMutation',
              keypath: 'lastIvmStockMutation',
              options: { unique: false },
            },
          ],
        },
        {
          store: 'ivmFastener',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'qrCode', keypath: 'qrCode', options: { unique: true } },
            { name: 'fastener', keypath: 'fastener', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'stock', keypath: 'stock', options: { unique: false } },
            {
              name: 'lastIvmStockMutation',
              keypath: 'lastIvmStockMutation',
              options: { unique: false },
            },
          ],
        },
        {
          store: 'ivmCassette',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'qrCode', keypath: 'qrCode', options: { unique: true } },
            { name: 'cassette', keypath: 'cassette', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'stock', keypath: 'stock', options: { unique: false } },
            {
              name: 'lastIvmStockMutation',
              keypath: 'lastIvmStockMutation',
              options: { unique: false },
            },
          ],
        },
        {
          store: 'canisterDrawer',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'isEmpty', keypath: 'isEmpty', options: { unique: false } },
          ],
        },
        {
          store: 'toolDrawer',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'drawerNumber', keypath: 'drawerNumber', options: { unique: false } },
            { name: 'coordinateX', keypath: 'coordinateX', options: { unique: false } },
            { name: 'coordinateY', keypath: 'coordinateY', options: { unique: false } },
            { name: 'isEmpty', keypath: 'isEmpty', options: { unique: false } },
          ],
        },
        {
          store: 'order',
          storeConfig: { keyPath: 'id', autoIncrement: false },
          storeSchema: [
            { name: 'orderNumber', keypath: 'orderNumber', options: { unique: false } },
            { name: 'deliveryAt', keypath: 'deliveryAt', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
            { name: 'fasteners', keypath: 'fasteners', options: { unique: false } },
            { name: 'tools', keypath: 'tools', options: { unique: false } },
            { name: 'part', keypath: 'part', options: { unique: false } },
            { name: 'startDateTime', keypath: 'startDateTime', options: { unique: false } },
          ],
        },
        {
          store: 'ivmSetting',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'name', keypath: 'name', options: { unique: true } },
            { name: 'value', keypath: 'value', options: { unique: false } },
          ],
        },
        //This is the table to store failed requests, added in version 7
        {
          store: 'requests',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'url', keypath: 'url', options: { unique: false } },
            { name: 'payload', keypath: 'payload', options: { unique: false } },
            { name: 'type', keypath: 'type', options: { unique: false } },
            { name: 'attempts', keypath: 'attempts', options: { unique: false } },
            { name: 'method', keypath: 'method', options: { unique: false } },
          ],
        },
        //These tables were added in version 8
        {
          store: 'customTools',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'name', keypath: 'name', options: { unique: true } },
            { name: 'organization', keypath: 'organization', options: { unique: false } },
            { name: 'synced', keypath: 'synced', options: { unique: false } },
          ],
        },
        {
          store: 'customToolSets',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'toolingSetId', keypath: 'toolingSetId', options: { unique: false } },
            { name: 'organization', keypath: 'organization', options: { unique: false } },
            { name: 'synced', keypath: 'synced', options: { unique: false } },
          ],
        },
        //These tables were added in version 9
        {
          store: 'auditTrail',
          storeConfig: { keyPath: 'id', autoIncrement: true },
          storeSchema: [
            { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
            { name: 'action', keypath: 'action', options: { unique: false } },
            { name: 'message', keypath: 'message', options: { unique: false } },
            { name: 'user', keypath: 'user', options: { unique: false } },
            { name: 'order_id', keypath: 'order_id', options: { unique: false } },
            { name: 'details', keypath: 'details', options: { unique: false } },
          ],
        },
      ],
      migrationFactory,
    };
  }
}
