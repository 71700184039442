import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { Order } from '@models/interfaces/order';

@Injectable({
  providedIn: 'root',
})
export class OrderRepository {
  constructor(private dbService: NgxIndexedDBService) {}

  getAllOrders() {
    return this.dbService.getAll<Order>('order');
  }

  addOrder(order: Order): Observable<Order> {
    return this.dbService.add('order', order);
  }

  getByOrderNumber(number: string): Observable<Order> {
    return this.dbService.getByIndex('order', 'orderNumber', number);
  }

  cancelOrder(orderNumber: string): Observable<Order[]> {
    return this.dbService.delete('order', orderNumber);
  }

  updateOrder(order: Order, data: Partial<Order>): Observable<Order[]> {
    return this.dbService.update('order', { ...order, ...data });
  }

  clearOrders() {
    return this.dbService.clear('order');
  }
}
