import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { IvmPart } from '@models/interfaces/ivmpart';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseIvmPartRepository<T> {
  abstract table: string;
  constructor(protected dbService: NgxIndexedDBService) {}

  findByPartName(name: string): Observable<T> {
    return this.dbService.getByIndex(this.table, 'partName', name);
  }

  update(ivmPart: T): void {
    this.dbService.update(this.table, ivmPart).subscribe(() => {});
  }

  getAll(): Observable<T[]> {
    return this.dbService.getAll(this.table);
  }

  find(id: number | undefined): Observable<T> {
    // @ts-ignore
    return this.dbService.getByID(this.table, id);
  }

  getAllByPartName(partName: string): Observable<T[]> {
    return this.dbService.getAllByIndex(this.table, 'partName', IDBKeyRange.only(partName));
  }

  findByQrCode(code: string): Observable<T> {
    return this.dbService.getByIndex(this.table, 'qrCode', code);
  }

  clear(): Observable<boolean> {
    return this.dbService.clear(this.table);
  }

  delete(ivmPart: IvmPart) {
    this.dbService.delete(this.table, ivmPart.id!).subscribe(() => {});
  }
}
