import { StockRequestCassetteFastener } from './stockrequestcassettefastener';

export interface StockRequest {
  '@id'?: string;
  'status'?: StockRequestStatus;
  readonly 'quantity'?: number;
  readonly 'estimatedTimeArrival'?: Date;
  readonly 'createdAt'?: Date;
  readonly 'updatedAt'?: Date;
  // TODO
  '@type'?: string;
  'fastener'?: any;
  'tool'?: any;
  'toolSet'?: any;
  'pennLocation'?: any;
  'order'?: any;
  'emptyWeight'?: number;
  'fasteners'?: StockRequestCassetteFastener[];
}

export enum StockRequestStatus {
  new = 'new',
  completed = 'completed',
  cancelled = 'cancelled',
}
