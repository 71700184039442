import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { MenuNavComponent } from './menunav/menu-nav.component';
import {
  MatLegacyTable as MatTable,
  MatLegacyTableModule as MatTableModule,
} from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [MenuNavComponent, MenuHeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    TranslateModule,
    NgScrollbarModule,
  ],
  exports: [MenuNavComponent, MaterialModule, MatTable, MenuHeaderComponent],
})
export class CoreModule {}
