import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Popup, PopupButtons } from '@models/utils/popup';

@Component({
  selector: 'cc-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.scss'],
})
export class GenericPopupComponent {
  constructor(
    private dialogRef: MatDialogRef<GenericPopupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: Popup,
  ) {}

  closeDialog() {
    this.dialogRef.close({ completed: true });
  }

  goToPath(button: PopupButtons) {
    if (button.path) void this.router.navigateByUrl(button.path);
    if (button.action) button.action();
    this.dialogRef.close({ completed: false });
  }
}
