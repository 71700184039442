import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { firstValueFrom, Observable } from 'rxjs';
import { RequestSync, SyncMethod } from '@models/utils/sync';

@Injectable({
  providedIn: 'root',
})
export class RequestRepositories {
  constructor(private dbService: NgxIndexedDBService) {}

  addRequest(
    url: string,
    payload: Object,
    type: string,
    method: SyncMethod,
    attempts = 0,
  ): Observable<RequestSync> {
    return this.dbService.add('requests', {
      url,
      payload,
      type,
      attempts,
      method,
    });
  }

  getRequests(): Observable<RequestSync[]> {
    return this.dbService.getAll('requests');
  }

  getRequest(requestId: number): Observable<RequestSync> {
    return this.dbService.getByID('requests', requestId);
  }

  getRequestsByType(type: string): Observable<RequestSync[]> {
    return this.dbService.getAllByIndex('requests', 'type', IDBKeyRange.only(type));
  }

  getRequestsByUrl(url: string): Observable<RequestSync[]> {
    return this.dbService.getAllByIndex('requests', 'url', IDBKeyRange.only(url));
  }

  deleteRequest(requestId: number) {
    return this.dbService.delete('requests', requestId);
  }

  updateRequest(request: RequestSync) {
    return this.dbService.update('requests', request);
  }

  /**
   * In case a request fails, we check if the request is already in the stack.
   * If yes, we increment the request attempts else we add it to the stack
   */
  async addOrUpdateSyncApiCall<T>(
    url: string,
    payload: T | null,
    method: SyncMethod,
    requestId?: number,
  ): Promise<RequestSync | undefined> {
    const type = url.split('/')[1];
    let request;
    if (requestId && (request = await firstValueFrom(this.getRequest(requestId)))) {
      request.attempts++;
      return (await firstValueFrom(this.updateRequest(request))).find(
        request => request.id === requestId,
      );
    } else {
      const requests = await firstValueFrom(this.getRequestsByUrl(url));
      const sameRequest = requests.filter(request => request.payload === payload).pop();

      if (sameRequest) {
        sameRequest.attempts++;
        return await (
          await firstValueFrom(this.updateRequest(sameRequest))
        ).find(request => request.id === sameRequest.id);
      }

      return await firstValueFrom(this.addRequest(url, payload as Object, type, method, 0));
    }
  }
}
