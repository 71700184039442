import { Injectable } from '@angular/core';
import {
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  Observable,
  of,
  retry,
  switchMap,
  tap,
} from 'rxjs';
import { SynchronizedIvmStockMutation } from '@models/interfaces/syncablestockmutation';
import { IvmFastenerRepository } from '@repositories/ivm-part/ivmfastener.repository';
import { StockMutationRepositoryService } from '@repositories/stockmutation/stock-mutation-repository.service';
import { FastenerService } from '../fastener/fastener.service';
import { Fastener } from '@models/interfaces/fastener';
import { IvmStockMutationActionType, StockLocationType } from '@models/utils/ivm-utils';
import { IvmFastener } from '@models/interfaces/ivmfastener';
import { IvmPart } from '@models/interfaces/ivmpart';
import { StockRequest } from '@models/interfaces/stockrequest';
import { IvmFastenerService } from '@services/ivm-part/Ivm-fastener/ivm-fastener.service';
import { IvmToolService } from '@services/ivm-part/Ivm-tool/ivm-tool.service';
import { IvmCassetteService } from '@services/ivm-part/Ivm-cassette/ivm-cassette.service';
import { IvmToolSetService } from '@services/ivm-part/Ivm-tool-set/ivm-tool-set.service';
import { IvmStockMutationService } from '@services/ivm-stock-mutation/ivm-stock-mutation.service';
import { IvmService } from '@services/Ivm/ivm.service';
import { IvmStockMutationApi } from '@api/ivm-stock-mutation.api';
import { IvmPartServiceI } from '@services/ivm-part';
import { IvmStockMutation } from '@models/interfaces/ivmstockmutation';
import { IvmStockMutationTransformer } from '../../transformer/ivm-stock-mutation.transformer';
import { OrderApiService } from '@services/order/order-api.service';

export type AddStockMutationParams = {
  ivmPart: IvmPart;
  quantity: number;
  action: IvmStockMutationActionType;
  orderNumber?: string;
  stockLocation?: StockLocationType;
  deleteIfEmpty?: boolean;
};

export type MoveStockMutationParams = {
  ivmPart: IvmPart;
  quantity: number;
  action: IvmStockMutationActionType;
  orderNumber?: string;
  fromLocation: StockLocationType;
  toLocation: StockLocationType;
  deleteIfEmpty?: boolean;
};

export type CanisterStockMutationParams = {};

@Injectable({
  providedIn: 'root',
})
export class StockMutationService {
  constructor(
    private stockMutationRepository: StockMutationRepositoryService,
    private ivmFastenerRepository: IvmFastenerRepository,
    private fastenerService: FastenerService,
    private ivmStockMutationTransformer: IvmStockMutationTransformer,
    private ivmFastenerService: IvmFastenerService,
    private ivmToolService: IvmToolService,
    private ivmToolSetService: IvmToolSetService,
    private ivmCassetteService: IvmCassetteService,
    private ivmService: IvmService,
    private ivmStockMutationApi: IvmStockMutationApi,
    private orderApiService: OrderApiService,
  ) {
    this.fastenerService.findAll().subscribe(() => console.log('fasteners preloaded'));
  }

  getPreviousMutationObservable(
    ivmPart: string,
  ): Observable<undefined | SynchronizedIvmStockMutation> {
    return this.stockMutationRepository
      .getByIvmPart(ivmPart)
      .pipe(map(mutations => mutations && mutations.pop()));
  }

  async addFirstCanister(canister: StockRequest, drawer: IvmPart): Promise<IvmFastener> {
    const uuid = canister['@id']!.split('/').pop();
    const fastener: Fastener | undefined = await firstValueFrom(
      this.fastenerService.find(canister.fastener),
    );
    if (!fastener) {
      console.log(canister);
      throw new Error('Fastener not found');
    }
    return await firstValueFrom(
      this.ivmFastenerRepository.add(
        'C' + uuid,
        fastener.partName,
        canister.fastener as string,
        drawer,
        canister.quantity ?? 0,
        canister.emptyWeight,
      ),
    );
  }

  async addStock(data: AddStockMutationParams) {
    return this.modifyStock(data);
  }

  async modifyStock({
    ivmPart,
    quantity,
    action,
    orderNumber,
    stockLocation,
    deleteIfEmpty = false,
  }: AddStockMutationParams): Promise<void> {
    let last_stock_mutation;
    let newStockValue = 0;
    let shouldDelete = false;

    const ivmPartService = this.getIvmPartService(ivmPart);

    if (!ivmPart.id) {
      throw new Error('Ivm part should have an id when adding stock mutation, but got empty id');
    }

    const ivmPartId = ivmPartService.getPartId(ivmPart);
    const stock = quantity;
    const ivmObject = await firstValueFrom(this.ivmService.getIvm());
    const order = await firstValueFrom(this.orderApiService.findByOrderNumber(orderNumber ?? ''));
    const orderUuid: string | undefined = order?.['@id'];

    //Update the stockLocation of the ivmpart, this shall be used when saving the stock mutation
    if (stockLocation) ivmPart = { ...ivmPart, stockLocation: stockLocation };

    const emptyActions: IvmStockMutationActionType[] = [
      'STOCK_CANISTER_RETURN_EMPTY',
      'STOCK_CANISTER_EMPTY',
      'STOCK_CANISTER_LOAN',
      'STOCK_MANUALLY_REMOVED',
    ];

    const previous_mutation: SynchronizedIvmStockMutation | undefined = await lastValueFrom(
      this.getPreviousMutationObservable(ivmPartId),
    );

    if (!previous_mutation) {
      newStockValue = quantity;
      last_stock_mutation = await lastValueFrom(
        this.stockMutationRepository.addStockMutation({
          ivmPartId,
          ivmPart,
          action,
          quantity,
          previousStock: 0,
          currentStock: stock,
          orderUuid,
        }),
      );
      newStockValue = quantity;
    } else {
      newStockValue =
        ivmPart.stockLocation === 'IVM' && emptyActions.includes(action)
          ? 0
          : (previous_mutation.stockAfter ?? 0) + quantity;

      // fix stock going below 0, which is not logical
      if (newStockValue < 0) {
        quantity -= newStockValue;
        newStockValue = 0;
      }

      last_stock_mutation = await lastValueFrom(
        this.stockMutationRepository.addStockMutation({
          ivmPartId,
          ivmPart,
          action,
          quantity,
          previousStock: previous_mutation.stockAfter ?? 0,
          currentStock: newStockValue,
          previous: previous_mutation['@id'],
          orderUuid,
        }),
      );
    }

    // stock mutation is final, sync it, and wait for it to complete, before sending the ivmPart to the backend
    await firstValueFrom(this.syncStockMutation(last_stock_mutation));

    const actionsToApplyStockUpdates: IvmStockMutationActionType[] = [
      'STOCK_ORANGE_CASSETTE_REMOVED',
      'STOCK_CANISTER_RETURN_EMPTY',
      'STOCK_TOOL_LOST',
      'STOCK_MANUALLY_REMOVED',
    ];
    //We should only update the stock of the ivm Part, when the mutation is done on the IVM
    if (ivmPart.stockLocation === 'IVM' || actionsToApplyStockUpdates.includes(action)) {
      ivmPart = { ...ivmPart, stock: newStockValue };
    }

    if (deleteIfEmpty && newStockValue <= 0) {
      shouldDelete = true;
    }

    // ivm part has changed, so update/delete it, but this can only be done after it has received an @id from the backend (if needed)
    if (!ivmPart['@id']) {
      ivmPartService.findOrCreate(ivmPart, ivmObject).then(remoteIvmPart => {
        ivmPart['@id'] = remoteIvmPart['@id'];
        this.updateOrDeleteIvmPart(ivmPart, ivmPartService, shouldDelete);
      });
    } else {
      this.updateOrDeleteIvmPart(ivmPart, ivmPartService, shouldDelete);
    }
  }

  updateOrDeleteIvmPart(
    ivmPart: IvmPart,
    ivmPartService: IvmPartServiceI<IvmPart>,
    deleteIfEmpty: boolean,
  ) {
    if (deleteIfEmpty) {
      ivmPartService.delete(ivmPart);
    } else {
      ivmPartService.update(ivmPart);
    }
  }

  async moveStock({ fromLocation, toLocation, quantity, ...data }: MoveStockMutationParams) {
    await this.modifyStock({
      ...data,
      stockLocation: fromLocation,
      quantity: -1 * quantity, //remove quantity from location A
    });

    await this.modifyStock({
      ...data,
      stockLocation: toLocation,
      quantity: quantity, //add quantity to location B
    });
  }

  addIvmStockMutation(
    lastStockMutation: SynchronizedIvmStockMutation,
  ): Observable<IvmStockMutation> {
    if (1 === lastStockMutation.synced) {
      return of(lastStockMutation);
    }

    return this.ivmStockMutationTransformer
      .transform(lastStockMutation)
      .pipe(switchMap(this.ivmStockMutationApi.create.bind(this.ivmStockMutationApi)));
  }

  getIvmPartService(ivmPart: IvmPart): IvmPartServiceI<IvmPart> {
    if (ivmPart.cassetteStockRequest) return this.ivmCassetteService;
    if (ivmPart.fastener) return this.ivmFastenerService;
    if (ivmPart.tool) return this.ivmToolService;
    if (ivmPart.toolSet) return this.ivmToolSetService;

    throw new Error('Invalid ivmPart');
  }

  // sync all unsynced mutations at the same time (returns indexedDb synced mutations)
  syncUnsyncedStockMutations() {
    return this.stockMutationRepository
      .getAllUnsyncedStockMutations()
      .pipe(
        switchMap(allUnsyncedMutations =>
          allUnsyncedMutations.length
            ? forkJoin(allUnsyncedMutations.map(this.syncStockMutation.bind(this)))
            : of([]),
        ),
      );
  }

  // sync a single stock mutation and return updated synced mutation that's now in indexedDb
  syncStockMutation(
    unsyncedMutation: SynchronizedIvmStockMutation,
  ): Observable<SynchronizedIvmStockMutation> {
    return this.addIvmStockMutation(unsyncedMutation).pipe(
      retry(1),
      switchMap(ivmStockMutationBackend =>
        this.stockMutationRepository.update({
          ...unsyncedMutation,
          'synced': 1,
          '@id': ivmStockMutationBackend?.['@id'],
        }),
      ),
      map(updateResult => updateResult[0]),
    );
  }
}
