import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomTool } from '@models/interfaces/customtool';
import { HydraResponse } from '@models/response/HydraResponse';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomToolApi {
  constructor(private http: HttpClient) {}

  create(name: string, ivm: string): Observable<HydraResponse<CustomTool>> {
    return this.http.post<HydraResponse<CustomTool>>(
      `${environment.apiBaseUrl}/api/custom_tools`,
      {
        name,
        ivm,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }
}
