import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { MaterialModule } from '@core/material/material.module';
import { CoreModule } from '@core/core.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RequestInterceptorService } from '@services/interceptor/request-interceptor.service';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthGuard } from '@guards/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { rootReducers, metaReducers, rootEffects } from '@reducers/store.reducer';
import { DBConfig, NgxIndexedDBModule, NgxIndexedDBService } from 'ngx-indexed-db';
import { EntityService } from '@services/entity.service';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ToastrModule } from 'ngx-toastr';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const dbConfig: DBConfig = new EntityService().databaseSetup();

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatTableModule,
    MatTooltipModule,
    HttpClientModule,
    MaterialModule,
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(rootReducers, { metaReducers }),
    EffectsModule.forRoot(rootEffects),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatInputModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatCardModule,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dbService: NgxIndexedDBService,
  ) {
    this.matIconRegistry.addSvgIcon('dashboard', this.setPath('../assets/icons/dashboard.svg'));
    this.matIconRegistry.addSvgIcon('settings', this.setPath('../assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon('help', this.setPath('../assets/icons/help-circle-grey.svg'));
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.setPath('../assets/icons/arrow-left-grey.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.setPath('../assets/icons/arrow-right-grey.svg'),
    );
    this.matIconRegistry.addSvgIcon('play', this.setPath('../assets/icons/play.svg'));
    this.matIconRegistry.addSvgIcon('funnel-gear', this.setPath('../assets/icons/funnel-gear.svg'));
    this.matIconRegistry.addSvgIcon('cross', this.setPath('../assets/icons/cross.svg'));
    this.matIconRegistry.addSvgIcon('arrow-up', this.setPath('../assets/icons/chevron-up.svg'));
    this.matIconRegistry.addSvgIcon('more-horiz', this.setPath('../assets/icons/more-horiz.svg'));
    this.matIconRegistry.addSvgIcon('arrow-down', this.setPath('../assets/icons/chevron-down.svg'));
    this.matIconRegistry.addSvgIcon('step-1', this.setPath('../assets/icons/qr1.svg'));
    this.matIconRegistry.addSvgIcon('step-2', this.setPath('../assets/icons/qr2.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.setPath('../assets/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon('delete', this.setPath('../assets/icons/trashcan.svg'));
    this.matIconRegistry.addSvgIcon('checkmark', this.setPath('../assets/icons/checkmark.svg'));
    this.matIconRegistry.addSvgIcon('refresh', this.setPath('../assets/icons/loading-arrow.svg'));
    this.matIconRegistry.addSvgIcon('stock', this.setPath('../assets/icons/barcode-reader.svg'));
    this.matIconRegistry.addSvgIcon('orders', this.setPath('../assets/icons/order-approve.svg'));
    this.matIconRegistry.addSvgIcon('warning', this.setPath('../assets/icons/warning.svg'));
    this.matIconRegistry.addSvgIcon('print', this.setPath('../assets/icons/print.svg'));
    this.matIconRegistry.addSvgIcon('activity', this.setPath('../assets/icons/activity.svg'));
    this.matIconRegistry.addSvgIcon('location', this.setPath('../assets/icons/location.svg'));
    this.matIconRegistry.addSvgIcon('qrcode', this.setPath('../assets/icons/qrcode.svg'));
    this.matIconRegistry.addSvgIcon('search', this.setPath('../assets/icons/search.svg'));
    this.matIconRegistry.addSvgIcon('gear', this.setPath('../assets/icons/gear.svg'));
    this.matIconRegistry.addSvgIcon('balance', this.setPath('../assets/icons/balance.svg'));
    this.matIconRegistry.addSvgIcon('arrow', this.setPath('../assets/icons/arrow.svg'));

    translate.addLangs(environment.lang);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang() ?? 'en';
    translate.use(browserLang);
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
