<div class="popup" [ngClass]="data.type === undefined ? 'modal-error': 'modal-'+data.type">
  <mat-toolbar class="content-header">
     <div class="header-title">{{ data.title | translate }}</div>
  </mat-toolbar>
  <div class="content">
    <div class="message">
      <h1 *ngIf="data.message">{{ data.message | translate }}</h1>
      <h1 *ngIf="data.messageFormatted" [innerHTML]="data.messageFormatted"></h1>
      <div class="video-container" *ngIf="data.video_src">
        <video autoplay loop>
          <source src="../../../assets/videos/{{data.video_src}}.webm" type="video/webm">
        </video>
      </div>
      <div *ngIf="!data.buttons">
        <button (click)="closeDialog()" class="btn primary">
          {{ data.cancel_text ?? 'button.try_again' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="data.buttons">
      <button *ngIf="!data.hide_cancel_button" (click)="closeDialog()" class="btn btn-secondary">
        {{ data.cancel_text ?? 'button.try_again' | translate }}
      </button>
      <button *ngFor="let button of data.buttons" class="btn" [ngClass]="button.class ? button.class : 'btn-danger'" (click)="goToPath(button)">
        {{ button.text | translate }}
      </button>
    </div>
  </div>
</div>

