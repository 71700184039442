import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, interval, map, Observable } from 'rxjs';
import { SyncMethod } from '@models/utils/sync';
import { RequestRepositories } from '@repositories/requests/requests.repository';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(private http: HttpClient, private requestRepository: RequestRepositories) {
    //Automatically start the syncing process after every 10mins
    interval(10 * 6 * 1000).subscribe(() => {
      this.syncFailedRequests().then(requestSynced => {});
    });
  }

  retryPayload<T>(
    url: string,
    payload: T,
    type: string,
    method: SyncMethod,
    requestId?: number,
  ): Observable<T> {
    const contentType = method === 'PATCH' ? 'application/merge-patch+json' : 'application/json';
    return this.http.request<T>(method, url, {
      body: payload,
      headers: { 'Content-Type': contentType },
    });
  }

  async syncFailedRequests() {
    const requests = await firstValueFrom(
      this.requestRepository.getRequests().pipe(map(requests => requests)),
    );

    requests.map(request => {
      this.retryPayload(
        request.url,
        request.payload,
        request.type,
        request.method,
        request.id,
      ).subscribe(() => {
        //request synced
        this.requestRepository.deleteRequest(request.id).subscribe();
      });
    });

    return requests.length;
  }
}
