export enum PrepareOrderActionsEnum {
  Initialize = '[PrepareOrder] Initialize',
  AddCassette = '[PrepareOrder] Add Cassette',
  AddCanisters = '[PrepareOrder] AddCanisters',
  UpdateFinalStep = '[PrepareOrder] UpdateFinalStep',
  UpdateWeightValue = '[UpdateWeightValue] UpdateWeight Value',
  Reset = '[PrepareOrder] Reset',
  Cancel = '[PrepareOrder] Cancel',

  CassetteScanQrCode = '[PrepareOrder] CassetteScanQrCode',
  CassettePlaceInFillUnit = '[PrepareOrder] CassettePlaceInFillUnit',

  CanisterScanQrCodeStart = '[PrepareOrder] CanisterScanQrCodeStart',
  CanisterScanQrCodeCompleted = '[PrepareOrder] CanisterScanQrCodeCompleted',

  CanisterPlaceInFillUnitStart = '[PrepareOrder] CanisterPlaceInFillUnitStart',
  CanisterPlaceInFillUnitCompleted = '[PrepareOrder] CanisterPlaceInFillUnitCompleted',

  CanisterWaitForFillingStart = '[PrepareOrder] CanisterWaitForFillingStart',
  CanisterWaitForFillingCompleted = '[PrepareOrder] CanisterWaitForFillingCompleted',

  CanisterRestartStep3 = '[PrepareOrder] CanisterRestartStep2',
  CanisterSkipStep3 = '[PrepareOrder] CanisterRestartStep3',

  CanisterBackInDrawerStart = '[PrepareOrder] CanisterBackInDrawerStart',
  CanisterBackInDrawerCompleted = '[PrepareOrder] CanisterBackInDrawerCompleted',

  FinalizeStart = '[PrepareOrder] FinalizeStart',
  FinalizeResetCassette = '[PrepareOrder] FinalizeResetCassette',
  FinalizeTakeOutOfFillUnit = '[PrepareOrder] FinalizeTakeOfFillUnit',
  FinalizePreparation = '[PrepareOrder] FinalizePreparation',

  AddStockMutationForCanisterStart = '[PrepareOrder] AddStockMutationForCanisterStart',
  AddStockMutationForCanisterSuccess = '[PrepareOrder] AddStockMutationForCanisterSuccess',
  AddStockMutationForCanisterFailure = '[PrepareOrder] AddStockMutationForCanisterFailure',

  StationLoaded = '[PrepareOrder] StationLoadedAction',
  EmptyAction = '[PrepareOrder] EmptyAction',
  ChangeCurrentCanister = '[PrepareOrder] ChangeCurrentCanister',
  EmergencyStop = '[PrepareOrder] EmergencyStop',
  SystemIsNotDosing = '[PrepareOrder] SystemIsNotDosing',
  ErrorOccurred = '[PrepareOrder] ErrorOccurred',
  RetryFilling = '[PrepareOrder] RetryFilling',
  MarkAsEmpty = '[PrepareOrder] MarkAsEmpty',
  PushCanisters = '[PrepareOrder] PushCanisters',
  UpdateNeededQuantity = '[PrepareOrder] UpdateNeededQuanity',
}

export enum PrepareOrderSteps {
  CassetteScanQrCode = 'cassette-scan-qr-code',
  CassettePlaceInFillUnit = 'cassette-place-in-full-unit',

  CanisterScanQrCode = 'canister-scan-qr-code',
  CanisterPlaceInFillUnit = 'canister-place-in-full-unit',
  CanisterWaitForFilling = 'canister-wait-for-filling',
  CanisterBackInDrawer = 'canister-back-in-drawer',

  FinalizeResetCassette = 'finalize-reset-cassette',
  FinalizeTakeOutOfFillUnit = 'finalize-take-out-of-fill-unit',
  FinalizePreparation = 'finalize-preparation',
}

export type PrepareOrderStepsType = keyof typeof PrepareOrderSteps;

export const IvmSlotPrefixNames = ['Hand Scanner', 'A↑', 'A↓', 'B↑', 'B↓'] as const;

export type IvmSlot = 'A' | 'B' | null;

// -slot: 1=A, 2=B
// -side: 0=top, 1=bottom

export type IvmSlotSide = 'top' | 'bottom';

export type StrictIvmSlot = Exclude<IvmSlot, null>;

export type IvmSlotPrefix = Record<StrictIvmSlot, number>;

export const ivmSlotDefinition: IvmSlotPrefix = { A: 1, B: 2 };
export const ivmSideDefinition: Record<IvmSlotSide, number> = { top: 0, bottom: 1 };

export interface IvmSlots {
  A: null | string;
  B: null | string;
}

export const slotDefinition: Record<number, typeof IvmSlotPrefixNames[number]> = {
  1: 'A↑',
  2: 'A↓',
  3: 'B↑',
  4: 'B↓',
};

export function convertSideToOldSlotDefinition(slot: number, side: number): number {
  switch (slot) {
    case 1:
      return side === 0 ? 1 : 2;
    case 2:
      return side === 0 ? 3 : 4;
    default:
      return 5; //Definitely wrong slot
  }
}
