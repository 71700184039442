import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tool } from '@models/interfaces/tool';
import { CustomToolService } from './custom-tool.service';
import { ToolApi } from '@api/tool.api';

@Injectable({
  providedIn: 'root',
})
export class ToolApiService {
  public tools$!: Observable<Tool[]>;

  constructor(
    private http: HttpClient,
    private customToolService: CustomToolService,
    private toolApi: ToolApi,
  ) {}

  getAll(): Observable<Tool[]> {
    return (this.tools$ ??= this.toolApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
      map(response => [...response, ...this.customToolService.getTools()]),
    ));
  }

  find(id: string): Observable<Tool | undefined> {
    return this.getAll().pipe(
      map((tools: Tool[]) =>
        tools.find((tool: Tool) => tool['@id'] === id || tool['@id']?.split('/').pop() === id),
      ),
    );
  }

  findByName(id: string): Observable<Tool | undefined> {
    return this.getAll().pipe(map((tools: Tool[]) => tools.find((tool: Tool) => tool.name === id)));
  }
}
