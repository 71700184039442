export enum OrderStatus {
  new = 'new',
  prepared = 'prepared',
  started = 'started',
  completed = 'completed',
}

export const LocationCoordinateMap: { [key: number | string]: string } = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
};

export const ivmStockMutationActions = [
  'STOCK_ADD', // in stock add
  'STOCK_REMOVED_FOR_FILL_UNIT', // in order prepare when removing stock after filling
  'STOCK_TOOL_LOAN', // in order start when reducing tool stock
  'STOCK_TOOL_RETURN', // in order finalize when re-increasing tool stock,
  'STOCK_TOOL_LOST', // The tool can be loss
  'STOCK_CANISTER_LOAN', // for auto machines only
  'STOCK_CANISTER_EMPTY', //The canister was empty unexpectedly
  'STOCK_CANISTER_RETURN', // for auto machines only,
  'STOCK_CANISTER_RETURN_EMPTY',
  'STOCK_AUTO_CORRECTION_EMPTY', // when fill unit detects empty
  'STOCK_MANUAL_CORRECTION', // for manual changes
  'STOCK_CANCEL_RETURN', // when canceling order, returning the stock (assuming manual correction by operator)
  'STOCK_REMOVED_FOR_PREFILL',
  'STOCK_REWEIGH', //modify the stock back to the correct amount
  'STOCK_CANISTER_USED', // stock assumed usage at the machine,
  'STOCK_ORANGE_CASSETTE_REMOVED', //when returning ivm cassette from the machine
  'STOCK_MANUALLY_REMOVED', //manualy delete the ivmpart from the stock page
] as const;

export type IvmStockMutationActionType = typeof ivmStockMutationActions[number];

export type StockLocationType = `M${number}` | 'IVM';
