import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { StoreFeatureSelectorEnum } from './store.enum';
import { PrepareOrderReducer, PrepareOrderState } from './order-prepare';
import { StockReducer, StockState } from './stocks/reducer';
import { hydrationMetaReducer } from './hydration.reducer';
import { PrepareOrderEffects } from './order-prepare/effects';

export interface AppState {
  [StoreFeatureSelectorEnum.PrepareOrder]: PrepareOrderState;
  [StoreFeatureSelectorEnum.Stock]: StockState;
}

export const rootReducers: ActionReducerMap<AppState> = {
  [StoreFeatureSelectorEnum.PrepareOrder]: PrepareOrderReducer,
  [StoreFeatureSelectorEnum.Stock]: StockReducer,
};

export const rootEffects = [PrepareOrderEffects];

export const metaReducers: MetaReducer<AppState>[] = [hydrationMetaReducer];
