import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { User } from '@models/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  constructor(private http: HttpClient) {}

  getAll(): Observable<HydraResponse<User>> {
    return this.http.get<HydraResponse<User>>(`${environment.apiBaseUrl}/api/users`);
  }
}
