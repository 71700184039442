import { Injectable } from '@angular/core';
import { CustomToolApi } from '@api/custom-tool.api';
import { CustomTool } from '@models/interfaces/customtool';
import { CustomToolRepository } from '@repositories/custom-tool/custom-tool.repository';
import { Observable, firstValueFrom, lastValueFrom, tap } from 'rxjs';
import { Ivm } from '@models/interfaces/ivm';

@Injectable({
  providedIn: 'root',
})
export class CustomToolService {
  private customTools: CustomTool[] = [];
  constructor(
    private customToolRepository: CustomToolRepository,
    private customToolApi: CustomToolApi,
  ) {}

  //When We add a custom tool locally, we need to sync it to the server
  async add(ivm: Ivm) {
    const lastCustomTool = (await lastValueFrom(this.customToolRepository.getAll())).pop();
    const previousId = lastCustomTool ? lastCustomTool.id : 0;

    const name = 'CUSTOM-TOOL-' + (previousId + 1);

    const newCustomTool = await firstValueFrom(this.customToolRepository.add({ name }));
    this.customToolApi.create(name, ivm?.['@id']).subscribe(customToolOnline => {
      this.customToolRepository.update({ ...newCustomTool, '@id': customToolOnline['@id'] });
      //This should reload the local data after syncing with the server
      this.getAll().subscribe();
    });
    await firstValueFrom(this.getAll());

    return name;
  }

  getAll(): Observable<CustomTool[]> {
    return this.customToolRepository.getAll().pipe(
      tap(tools => {
        this.customTools = tools;
      }),
    );
  }

  create(data: { 'name': string; '@id'?: string }): Observable<CustomTool> {
    return this.customToolRepository.add(data);
  }

  getTools() {
    return this.customTools;
  }
}
