import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { IvmToolSet } from '@models/interfaces/ivmtoolset';
import { IvmToolSetApi } from '@api/ivm-tool-set.api';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmTool } from '@models/interfaces/ivmtool';
import { SynchronizedIvmToolSet } from '@models/interfaces/syncableivmtoolset';
import { Ivm } from '@models/interfaces/ivm';
import { IvmToolSetRepository } from '@repositories/ivm-part/ivmtoolset.repository';
import { BaseIvmPartService, IvmPartServiceI } from '..';

@Injectable({
  providedIn: 'root',
})
export class IvmToolSetService extends BaseIvmPartService implements IvmPartServiceI<IvmToolSet> {
  override api_url: string = '/api/ivm_tool_sets';
  private ivmToolSets?: Observable<IvmToolSet[]>;

  constructor(
    private ivmToolSetApi: IvmToolSetApi,
    private ivmToolSetRepository: IvmToolSetRepository,
  ) {
    super();
  }

  findAll(ivm: string): Observable<IvmToolSet[]> {
    return (this.ivmToolSets ??= this.ivmToolSetApi.getAll(ivm).pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  findById(id: number, ivm: string): Observable<IvmToolSet | undefined> {
    return this.findAll(ivm).pipe(
      map((ivmToolSets: IvmToolSet[]) =>
        ivmToolSets.find((ivmToolSet: IvmToolSet) => ivmToolSet['ivmPartId'] == id),
      ),
    );
  }

  create(ivmToolSet: IvmToolSet): Observable<IvmTool> {
    return this.ivmToolSetApi.create(ivmToolSet).pipe(tap(() => (this.ivmToolSets = undefined)));
  }

  async findOrCreate(ivmPart: IvmToolSet, ivmObject?: Ivm) {
    const ivm = ivmObject?.['@id']?.split('/').pop() ?? '';
    let ivmToolSetRemote;

    ivmToolSetRemote = await firstValueFrom(this.findById(ivmPart.id ?? 0, ivm));

    if (ivmToolSetRemote === undefined) {
      let ivmToolSetObj: SynchronizedIvmToolSet = {
        ivmPartId: ivmPart.id ?? 0,
        qrCode: ivmPart.qrCode,
        partName: ivmPart.partName,
        coordinateX: ivmPart.coordinateX,
        coordinateY: ivmPart.coordinateY,
        drawerNumber: ivmPart.drawerNumber,
        unit: ivmPart.unit ?? 0,
        stock: ivmPart.stock ?? 0,
        toolSet: ivmPart.toolSet,
        stockLocation: ivmPart.stockLocation,
        ivm: ivmObject?.['@id'] ?? '',
      };
      ivmToolSetRemote = await firstValueFrom(this.create(ivmToolSetObj));
    }

    return ivmToolSetRemote;
  }

  update(ivmPart: IvmToolSet) {
    this.ivmToolSetRepository.update(ivmPart);
    if (ivmPart['@id']) this.ivmToolSetApi.update(ivmPart).subscribe();
  }

  delete(ivmPart: IvmToolSet) {
    this.ivmToolSetRepository.delete(ivmPart);
    if (ivmPart['@id']) this.ivmToolSetApi.delete(ivmPart).subscribe();
  }
}
