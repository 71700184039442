import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  Observable,
  retry,
  share,
  switchMap,
  throwError,
  timeout,
  TimeoutError,
} from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RequestRepositories } from '@repositories/requests/requests.repository';
import { SyncMethod } from '@models/utils/sync';
import { ConnectionService } from '../sync-service/connection.service';

const HTTP_TIMEOUT_IN_MS = 30000;

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private toaster: ToastrService,
    private requestRepository: RequestRepositories,
    private connectionService: ConnectionService,
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // ignore non-api requests
    if (!req.url.startsWith(environment.apiBaseUrl + '/api/')) {
      return next.handle(req);
    }

    // for api requests, add authorization token
    return this.auth.getToken(false).pipe(
      switchMap((token: string | null) => {
        req = RequestInterceptorService.addToken(req, token);

        return next.handle(req).pipe(
          timeout(HTTP_TIMEOUT_IN_MS),
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.auth.getToken(true).pipe(
                switchMap((token: string | null) => {
                  req = RequestInterceptorService.addToken(req, token);
                  return next.handle(req);
                }),
              );
            }

            return throwError(() => error);
          }),
          share(),
        );
      }),
      catchError(e => {
        if ([400, 422, 500].includes(e.status)) {
          this.toaster.error(e.error['hydra:description'] ?? e.message, 'Api Error');
          return throwError(() => e);
        }

        if (e.status === 401) {
          localStorage.setItem('isLoggedIn', 'false');
          void this.router.navigateByUrl('/login');
          return EMPTY;
        }

        return this.handleError(e, req);
      }),
    );
  }

  private static addToken<T>(req: HttpRequest<T>, token: string | null) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private handleError<T>(err: any, req: HttpRequest<T>): Observable<any> {
    if (this.offlineOrBadConnection(err)) {
      // A client-side or network error occurred. Handle it accordingly.

      const method = RequestInterceptorService.mapMethod(req.method);
      if (method) void this.requestRepository.addOrUpdateSyncApiCall<T>(req.url, req.body, method);

      return EMPTY;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.

      //TODO should throw the actual error
      //For now when we throw the actual error, the test fails.
      //Should work on tests in SFRD-41 to make mocks for the /api/fasteners endpoint
      return EMPTY;
    }
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError || !this.connectionService.isOnline // A helper service that delegates to window.navigator.onLine
    );
  }

  private static mapMethod(method: string): SyncMethod | null {
    switch (method.toLocaleLowerCase()) {
      case 'post':
        return 'POST';
      case 'patch':
        return 'PATCH';
      case 'put':
        return 'PUT';
      case 'delete':
        return 'DELETE';
      default:
        return null;
    }
  }
}
