import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, instrumentAngularRouting } from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/browser';

if (environment.production || environment.staging) {
  enableProdMode();

  init({
    dsn: environment.sentryDsn,
    environment: environment.environment,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          'localhost',
          '0.0.0.0',
          'https://ivm.penn-connect.com',
          'https://admin.penn-connect.com',
          'https://staging-admin.penn-connect.com',
          'https://staging-ivm-frontend.penn-connect.com',
        ],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    tracesSampleRate: 1,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
