import { createAction, props } from '@ngrx/store';
import { StateToggleItem, StateToggleStatus } from '@interfaces/utils/state-toggle';
import { Cassette } from '@models/interfaces/cassette';
import { IvmSlot, PrepareOrderActionsEnum } from './enum';
import { CanisterDetailsInterface, PrepareOrderPropertiesInterface } from './properties';

export class PrepareOrderActions {
  public static readonly initialize = createAction(
    PrepareOrderActionsEnum.Initialize,
    props<PrepareOrderPropertiesInterface>(),
  );
  public static readonly addCanisters = createAction(
    PrepareOrderActionsEnum.AddCanisters,
    props<{
      canisters: Array<
        Pick<StateToggleItem, 'id' | 'title' | 'subtitle'> & { details: CanisterDetailsInterface }
      >;
    }>(),
  );

  public static readonly pushCanisters = createAction(
    PrepareOrderActionsEnum.PushCanisters,
    props<{
      canisterIndex: number;
      station?: number;
      canisters: Array<
        Pick<StateToggleItem, 'id' | 'title' | 'subtitle'> & { details: CanisterDetailsInterface }
      >;
    }>(),
  );
  public static readonly addCassette = createAction(
    PrepareOrderActionsEnum.AddCassette,
    props<{ cassette: Cassette }>(),
  );

  public static readonly reset = createAction(PrepareOrderActionsEnum.Reset);

  public static readonly cancel = createAction(PrepareOrderActionsEnum.Cancel);

  public static readonly updateWeightValue = createAction(
    PrepareOrderActionsEnum.UpdateWeightValue,
    props<PrepareOrderPropertiesInterface>(),
  );

  public static readonly cassetteScanQrCode = createAction(
    PrepareOrderActionsEnum.CassetteScanQrCode,
    props<{ status: StateToggleStatus }>(),
  );

  public static readonly cassettePlaceInFillUnit = createAction(
    PrepareOrderActionsEnum.CassettePlaceInFillUnit,
    props<{ status: StateToggleStatus }>(),
  );

  public static readonly canisterScanQrCodeStart = createAction(
    PrepareOrderActionsEnum.CanisterScanQrCodeStart,
    props<{ canisterIndex: number }>(),
  );

  public static readonly canisterScanQrCodeCompleted = createAction(
    PrepareOrderActionsEnum.CanisterScanQrCodeCompleted,
    props<{ canisterIndex: number; slot: 'A' | 'B' }>(),
  );

  public static readonly canisterPlaceInFillUnitStart = createAction(
    PrepareOrderActionsEnum.CanisterPlaceInFillUnitStart,
    props<{ canisterIndex: number }>(),
  );
  public static readonly canisterPlaceInFillUnitCompleted = createAction(
    PrepareOrderActionsEnum.CanisterPlaceInFillUnitCompleted,
    props<{ canisterIndex: number; disableQueue?: boolean }>(),
  );

  public static readonly canisterWaitForFillingStart = createAction(
    PrepareOrderActionsEnum.CanisterWaitForFillingStart,
    props<{ canisterIndex: number; disableQueue?: boolean }>(),
  );
  public static readonly canisterWaitForFillingCompleted = createAction(
    PrepareOrderActionsEnum.CanisterWaitForFillingCompleted,
    props<{ canisterIndex: number }>(),
  );

  public static readonly canisterRestartStep3 = createAction(
    PrepareOrderActionsEnum.CanisterRestartStep3,
    props<{ canisterIndex: number }>(),
  );

  public static readonly canisterSkipStep3 = createAction(
    PrepareOrderActionsEnum.CanisterSkipStep3,
    props<{ canisterIndex: number }>(),
  );

  public static readonly canisterBackInDrawerStart = createAction(
    PrepareOrderActionsEnum.CanisterBackInDrawerStart,
    props<{ canisterIndex: number; isDynamic: boolean }>(),
  );
  public static readonly canisterBackInDrawerCompleted = createAction(
    PrepareOrderActionsEnum.CanisterBackInDrawerCompleted,
    props<{ canisterIndex: number }>(),
  );

  public static readonly finalizeStart = createAction(PrepareOrderActionsEnum.FinalizeStart);
  public static readonly finalizeResetCassette = createAction(
    PrepareOrderActionsEnum.FinalizeResetCassette,
    props<{ status: StateToggleStatus }>(),
  );

  public static readonly finalizeTakeOutOfFillUnit = createAction(
    PrepareOrderActionsEnum.FinalizeTakeOutOfFillUnit,
  );

  public static readonly finalizePreparation = createAction(
    PrepareOrderActionsEnum.FinalizePreparation,
  );

  public static readonly addStockMutationForCanisterStart = createAction(
    PrepareOrderActionsEnum.AddStockMutationForCanisterStart,
    props<{ canisterIndex: number; dosingDuration: string; slot: IvmSlot }>(),
  );

  public static readonly addStockMutationForCanisterSuccess = createAction(
    PrepareOrderActionsEnum.AddStockMutationForCanisterSuccess,
    props<{ canisterIndex: number; isEmpty: boolean }>(),
  );

  public static readonly stationLoaded = createAction(PrepareOrderActionsEnum.StationLoaded);

  public static readonly changeCurrentCanister = createAction(
    PrepareOrderActionsEnum.ChangeCurrentCanister,
    props<{ canisterIndex: number }>(),
  );

  public static readonly emergencyStop = createAction(
    PrepareOrderActionsEnum.EmergencyStop,
    props<{ canisterIndex?: number }>(),
  );

  public static readonly errorOccured = createAction(
    PrepareOrderActionsEnum.ErrorOccurred,
    props<{ error: string; shouldAlert: boolean }>(),
  );

  public static readonly retryFilling = createAction(
    PrepareOrderActionsEnum.RetryFilling,
    props<{ canisterIndex: number }>(),
  );
  public static readonly markAsEmpty = createAction(
    PrepareOrderActionsEnum.MarkAsEmpty,
    props<{ canisterIndex: number; quantityUsed: number }>(),
  );
  public static readonly updateFastenerQuantityInAFastener = createAction(
    PrepareOrderActionsEnum.UpdateNeededQuantity,
    props<{ canisterIndex: number; quantityToUpdate: number }>(),
  );

  public static readonly emptyAction = createAction(PrepareOrderActionsEnum.EmptyAction);
}
