import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IvmFastener } from '@models/interfaces/ivmfastener';
import { Observable, tap } from 'rxjs';
import { IvmPart } from '@models/interfaces/ivmpart';
import { IvmFastenerExtended } from '@models/extended/IvmFastenerExtended';
import { BaseIvmPartRepository } from '.';

@Injectable({
  providedIn: 'root',
})
export class IvmFastenerRepository extends BaseIvmPartRepository<IvmFastener> {
  table = 'ivmFastener';

  add(
    code: string,
    partName: string,
    fastener: string,
    drawer: IvmPart,
    stock: number,
    emptyWeight = 0,
  ): Observable<IvmFastenerExtended> {
    const data: IvmFastener = {
      qrCode: code,
      partName: partName, // TODO
      fastener: fastener,
      coordinateX: String(drawer.coordinateX),
      coordinateY: String(drawer.coordinateY),
      drawerNumber: drawer.drawerNumber,
      unit: drawer.unit,
      stock: stock,
      stockLocation: drawer.stockLocation,
      emptyWeight: emptyWeight,
      ...(drawer['ivmPartId'] && { id: drawer['ivmPartId'] }),
      ...(drawer['@id'] && { '@id': drawer['@id'] }),
    };
    return this.dbService.add('ivmFastener', data);
  }

  reAdd(ivmFastener: IvmFastener) {
    const { id, ...newIvmFastener } = ivmFastener; //we want to remove the previous id.
    return this.dbService.add('ivmFastener', newIvmFastener);
  }

  getByFastener(fastener: string): Observable<IvmFastener[]> {
    return this.dbService.getAllByIndex('ivmFastener', 'fastener', IDBKeyRange.only(fastener));
  }

  getByPartName(partName: string): Observable<IvmFastener[]> {
    return this.dbService.getAllByIndex(this.table, 'partName', IDBKeyRange.only(partName));
  }

  getByDrawerNumber(drawerNumber: number): Observable<IvmFastener[]> {
    return this.dbService.getAllByIndex(
      'ivmFastener',
      'drawerNumber',
      IDBKeyRange.only(drawerNumber),
    );
  }

  async findFasteners(fastener: string): Promise<IvmFastener[]> {
    return new Promise((resolve, reject) => {
      this.getByFastener(fastener).subscribe(ivmFasteners => resolve(ivmFasteners));
    });
  }

  async findSortedFasteners(fastener: string): Promise<IvmFastener[]> {
    return (await this.findFasteners(fastener))
      .filter(ivmFastener => ivmFastener.stock && ivmFastener.stock > 0)
      .sort((a, b) => ((a.stock ?? 0) > (b.stock ?? 0) ? 1 : -1)) as IvmFastener[];
  }
}
