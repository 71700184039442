import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable, of, switchMap, tap } from 'rxjs';
import { IvmTool } from '@models/interfaces/ivmtool';
import { Tool } from '@models/interfaces/tool';
import { IvmPart } from '@models/interfaces/ivmpart';
import { BaseIvmPartRepository } from '.';

@Injectable({
  providedIn: 'root',
})
export class IvmToolRepository extends BaseIvmPartRepository<IvmTool> {
  table = 'ivmTool';

  add(qrCode: string, tool: Tool, drawer: IvmPart): Observable<IvmTool> {
    if (!drawer.coordinateX || !drawer.coordinateY || !drawer.drawerNumber) {
      throw new Error('Invalid coordinates and/or drawer: ' + JSON.stringify(drawer));
    }

    const data: IvmTool = {
      qrCode: qrCode,
      partName: tool.name,
      tool: tool['@id'],
      coordinateX: drawer.coordinateX,
      coordinateY: drawer.coordinateY,
      drawerNumber: drawer.drawerNumber,
      unit: drawer.unit,
      stock: 1,
      stockLocation: drawer.stockLocation,
      ...(drawer['ivmPartId'] && { id: drawer['ivmPartId'] }),
      ...(drawer['@id'] && { '@id': drawer['@id'] }),
    };
    return this.dbService.add('ivmTool', data);
  }

  //We can have tool or custom tool.
  findByTool(tool: string): Observable<IvmTool[]> {
    const uuid = '/api/tools/' + tool.split('/').pop();
    return this.dbService.getAllByIndex('ivmTool', 'tool', IDBKeyRange.only(uuid));
  }

  findByCustomTool(customTool: string): Observable<IvmTool[]> {
    const uuid = '/api/custom_tools/' + customTool.split('/').pop();
    return this.dbService.getAllByIndex('ivmTool', 'tool', IDBKeyRange.only(uuid));
  }

  findToolOrCustom(toolId: string): Observable<IvmTool[]> {
    return this.findByTool(toolId).pipe(
      switchMap(ivmTools => (ivmTools.length ? of(ivmTools) : this.findByCustomTool(toolId))),
    );
  }
}
