import { IvmCassette } from '@models/interfaces/ivmcassette';
import { IvmFastenerService } from './Ivm-fastener/ivm-fastener.service';
import { IvmToolSetService } from './Ivm-tool-set/ivm-tool-set.service';
import { IvmToolService } from './Ivm-tool/ivm-tool.service';
import { Observable } from 'rxjs';
import { Ivm } from '@models/interfaces/ivm';
import { IvmPart } from '@models/interfaces/ivmpart';

export interface IvmPartServiceI<T> {
  findAll(ivm: string): Observable<T[]>;

  create(data: T): Observable<T>;

  delete(ivmPart: T): void;

  findOrCreate(ivmPart: T, ivm?: Ivm): Promise<T>;

  update(ivmPart: T): void;

  getPartId(ivmPart: T): string;
}

export type IvmPartService = IvmFastenerService | IvmToolService | IvmToolSetService | IvmCassette;

export abstract class BaseIvmPartService {
  abstract api_url: string;
  getPartId(ivmPart: IvmPart) {
    return `${this.api_url}/${ivmPart.id}`;
  }
}
