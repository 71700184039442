import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmTool } from '@models/interfaces/ivmtool';

@Injectable({
  providedIn: 'root',
})
export class IvmToolApi {
  constructor(private http: HttpClient) {}

  getAll(ivm: string | undefined): Observable<HydraResponse<IvmTool>> {
    return this.http.get<HydraResponse<IvmTool>>(
      `${environment.apiBaseUrl}/api/ivm_tools?ivm=` + ivm,
    );
  }

  create(ivmTool: IvmTool): Observable<IvmTool> {
    return this.http.post<IvmTool>(
      `${environment.apiBaseUrl}/api/ivm_tools`,
      {
        ...ivmTool,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  update(ivmTool: IvmTool): Observable<IvmTool> {
    return this.http.put<IvmTool>(
      `${environment.apiBaseUrl}${ivmTool['@id']}`,
      {
        stock: ivmTool.stock,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  delete(ivmTool: IvmTool): Observable<IvmTool> {
    return this.http.delete<IvmTool>(`${environment.apiBaseUrl}${ivmTool['@id']}`, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
