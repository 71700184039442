import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreFeatureSelectorEnum } from '../store.enum';
import { PrepareOrderState } from './reducer';

export class PrepareOrderSelectors {
  // This returns the PrepareOrder state as defined in the reducer
  public static readonly getState = createFeatureSelector<PrepareOrderState>(
    StoreFeatureSelectorEnum.PrepareOrder,
  );

  public static readonly selectState = createSelector(
    PrepareOrderSelectors.getState,
    (state: PrepareOrderState) => state,
  );

  // More selectors
}
