import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Ivm } from '@models/interfaces/ivm';
import { IvmApi } from '@api/ivm.api';

@Injectable({
  providedIn: 'root',
})
export class IvmService {
  static TIMEOUT = 30; // seconds
  private ivms!: Observable<Ivm[]> | undefined;

  constructor(private ivmApi: IvmApi) {}

  findAll(): Observable<Ivm[]> {
    //setTimeout(() => (this.ivms = undefined), IvmService.TIMEOUT * 1000);
    return (this.ivms ??= this.ivmApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  getIvm(): Observable<Ivm | undefined> {
    return this.findAll().pipe(
      map((response: Ivm[]) => {
        return response[response.length - 1];
      }),
    );
  }

  getIvmId(): Observable<string> {
    return this.getIvm().pipe(
      map(ivm => {
        return ivm!['@id']?.split('/').pop() ?? '';
      }),
    );
  }
}
