import { Injectable } from '@angular/core';
import { Order } from '@models/interfaces/order';
import { CalculationStation } from '@models/interfaces/calculationstation';
import { IvmToolSetRepository } from '@repositories/ivm-part/ivmtoolset.repository';
import { firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { ToolSetApiService } from '../stock/tool-set-api.service';
import { IvmToolRepository } from '@repositories/ivm-part/ivmtool.repository';
import { ToolApiService } from '../stock/tool-api.service';
import { CanisterStepType } from '@reducers/order-prepare';
import { OrderFastener, OrderStatus } from '@models/utils/order-fastener';
import { LocationCoordinateMap } from '@models/utils/ivm-utils';
import { IvmFastenerRepository } from '@repositories/ivm-part/ivmfastener.repository';
import { IvmFastenerExtended } from '@models/extended/IvmFastenerExtended';
import { IvmFastener } from '@models/interfaces/ivmfastener';
import { STATION_COLORS } from '@interfaces/utils/station-colors';
import { OrderApiService } from './order-api.service';
import { OrderRepository } from '@repositories/order/order.repository';
import { GenericPopupComponent } from '@components/generic-popup/generic-popup.component';
import { StockRequest } from '@models/interfaces/stockrequest';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { StockRequestService } from '@services/stock-request/stock-request.service';
import { IvmTool } from '@models/interfaces/ivmtool';
import { IvmToolSet } from '@models/interfaces/ivmtoolset';
import { ToolUpdatesInterface } from '@pages/orders/order-start/state/steps.models';
import { PopupButtons } from '@models/utils/popup';
import { ToastrService } from 'ngx-toastr';
import { CalculationStationService } from '@services/calculation-station/calculation-station.service';
import { UpdateOrderParams } from '@models/extended/order';
import { Fastener } from '@models/interfaces/fastener';
import { FastenerService } from '@services/fastener/fastener.service';
import { CalculationStep } from '@models/interfaces/calculationstep';

export interface ToolDetailsInterface {
  '@id'?: string;
  'ivmTool': IvmTool | IvmToolSet;
  'name': string;
  'type': 'tool' | 'toolset';
  'qrCode': string;
  'color': string;
  'station': number;
}

class NoSpaceInDrawerError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, NoSpaceInDrawerError.prototype); // Set the prototype explicitly.
  }
}

export enum ToolFilters {
  tool = 'api/tools',
  toolset = 'api/tool_sets',
  custom_tool = 'api/custom_tools',
  custom_toolset = 'api/custom_tool_sets',
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private ivmToolSetRepository: IvmToolSetRepository,
    private ivmToolRepository: IvmToolRepository,
    private toolSetApiService: ToolSetApiService,
    private toolApiService: ToolApiService,
    private fastenerRepository: IvmFastenerRepository,
    private orderApiService: OrderApiService,
    private orderRepository: OrderRepository,
    public dialog: MatDialog,
    private translate: TranslateService,
    private stockRequestService: StockRequestService,
    private calculationStationService: CalculationStationService,
    private fastenerService: FastenerService,
  ) {}

  checkAutomaticStations(order: Order): {
    hasAutomatic: boolean;
    isFullyAutomatic: boolean;
    usesPrefilledCassette: boolean;
  } {
    let hasAutomatic = false;
    const usesPrefilledCassette = !!order.cassetteStockRequest;

    //If the order has some fasteners, then by default, we assume they are all using automatic stations
    let isFullyAutomatic = !!order.fabricationPart.calculationStations.length;
    order.fabricationPart?.calculationStations?.map((station: CalculationStation) => {
      if (station.isAutomatic) hasAutomatic = true;
      else isFullyAutomatic = false;
    });
    return { hasAutomatic, isFullyAutomatic, usesPrefilledCassette };
  }

  getOrderFastenersForAutomaticStations(order: Order) {
    const fastenersForAutomaticStations = order.fabricationPart?.calculationStations
      .filter((station: CalculationStation) => station.isAutomatic)
      .map((station: CalculationStation) => station.fastener);

    return order.fabricationPart?.calculationStations.filter((station: CalculationStation) =>
      fastenersForAutomaticStations.includes(station.fastener),
    );
  }

  async getValidCanisters(
    order: Order,
    toReturn = false,
    margin = 0,
    lowestSufficientFirst = false,
    machineNumber: number = 0,
  ): Promise<CanisterStepType[]> {
    return (
      await Promise.all(
        this.getOrderFastenersForAutomaticStations(order).map(
          async (station: CalculationStation) => {
            let ivmFasteners: IvmFastener[] = await this.fastenerRepository.findFasteners(
              station.fastener as unknown as string,
            );

            // TODO the "filter" should be part of the query. There's a different query for start and finalize..
            if (!toReturn) {
              ivmFasteners = ivmFasteners
                .filter(
                  ivmFastener =>
                    ivmFastener.stockLocation === 'IVM' &&
                    ivmFastener.stock &&
                    ivmFastener.stock > 0,
                )
                .sort((a, b) => (a.stock ?? 0 > (b.stock ?? 0) ? 1 : -1));
            } else {
              ivmFasteners = ivmFasteners.filter(
                ivmFastener => ivmFastener.stockLocation === 'M' + machineNumber,
              );
            }

            const fastener: Fastener | undefined = await firstValueFrom(
              this.fastenerService.find(station.fastener as unknown as string),
            );
            let calculationStepObj = order.fabricationPart.calculationSteps.find(
              (step: CalculationStep) =>
                step.calculationStation?.['@id']?.split('/').pop() === station.id,
            );
            return this.mapValidCanister(
              order,
              ivmFasteners,
              (calculationStepObj?.amount ?? 1) * (order.batch ?? 1),
              fastener ? fastener.partName : '',
              margin,
              lowestSufficientFirst,
            );
          },
        ),
      )
    ).flat() as CanisterStepType[];
  }

  private async mapValidCanister(
    order: Order,
    ivmFasteners: Array<IvmFastenerExtended>,
    quantity: number,
    partName: string,
    margin: number,
    lowestSufficientFirst = false,
  ): Promise<Array<Partial<CanisterStepType>>> {
    let amountNeeded = quantity;
    let canisters: Partial<CanisterStepType>[] = [];

    if (lowestSufficientFirst) {
      const quantityNeededWithMargin = amountNeeded + (amountNeeded * margin) / 100;
      const lowestSufficientFasteners = ivmFasteners.filter(
        ivmFastener => (ivmFastener.stock ?? 0) >= quantityNeededWithMargin,
      );

      if (lowestSufficientFasteners.length) {
        ivmFasteners = lowestSufficientFasteners;
      }
    }
    for (const ivmFastener of ivmFasteners) {
      const station = order.fabricationPart.calculationStations
        .filter((station: CalculationStation) => ivmFastener.fastener === station.fastener)
        .shift();

      if (!station) continue;
      const canisterStock = ivmFastener.stock ?? 0;
      //if the stock for this canister is less than required, then we only take the available stock quantity.
      const quantityForCanister = canisterStock < amountNeeded ? canisterStock : amountNeeded;
      canisters.push({
        id: ivmFastener.qrCode,
        title: `Un ${ivmFastener.unit} - Dr ${ivmFastener.drawerNumber} - ${
          LocationCoordinateMap[ivmFastener.coordinateY as string]
        }${ivmFastener.coordinateX}`,
        subtitle: `${partName} ${quantityForCanister}X`,
        details: {
          ivmFastener: ivmFastener,
          quantity: quantityForCanister,
          totalQuantity: quantity,
          weight: 0,
          currentWeight: 0,
          targetWeight: 0,
          totalWeight: 0,
          color: STATION_COLORS[station.number - 1],
          station: station.number,
        },
        steps: [],
        status: 'PENDING',
      });

      //If the current canister has more than {margin}% of the quantity needed, we stop here
      const quantityNeededWithMargin = amountNeeded + (amountNeeded * margin) / 100;
      if (canisterStock > quantityNeededWithMargin) {
        break;
      }

      //If we have reached the quantity we needed, then we stop
      amountNeeded = amountNeeded - canisterStock;
      if (amountNeeded < 1) {
        break;
      }
    }

    return canisters;
  }

  async getValidTools(order: Order, toReturn = false): Promise<ToolDetailsInterface[]> {
    return (
      await Promise.all(
        order.fabricationPart.calculationStations?.map(async (station: CalculationStation) => {
          let customTools: ToolDetailsInterface[] = [];
          //get custom Tools
          if (station.customToolSet) {
            customTools.push(
              (
                await this.getToolSetDetails(
                  station.customToolSet,
                  station.number as number,
                  toReturn,
                )
              )[0],
            );
          }

          if (station.customTools?.length) {
            customTools = [
              ...customTools,
              ...(await Promise.all(
                station.customTools.map(customTool =>
                  this.getToolDetails(customTool, station.number as number, toReturn),
                ),
              )),
            ];
          }

          if (station.tools?.length) {
            customTools = [
              ...customTools,
              ...(await Promise.all(
                station.tools.map(async tool =>
                  this.getToolDetails(tool, station.number as number, toReturn),
                ),
              )),
            ];
          }

          if (station.isAutomatic) {
            if (station.toolSet) {
              //We add this check because a toolset can be removed in the start flow
              return [
                customTools,
                ...(await this.getToolSetDetails(
                  station.toolSet as string,
                  station.number as number,
                  toReturn,
                )),
              ].flat();
            } else return customTools.flat();
          }

          return customTools.flat();
        }),
      )
    ).flat();
  }

  //TODO: This function should not really be needed after merging CC-597
  public async getManualTools(order: Order): Promise<Order> {
    await Promise.all(
      order.fabricationPart.calculationStations?.map(async (station: CalculationStation) => {
        const manualTools: string[] = [];
        const toolSet = await firstValueFrom(
          this.toolSetApiService.find(station.toolSet as string),
        );

        if (!station.isAutomatic) {
          if (toolSet?.lowerTool && !station.tools.includes(toolSet?.lowerTool))
            station.tools.push(toolSet.lowerTool);
          if (toolSet?.upperTool && !station.tools.includes(toolSet?.upperTool))
            station.tools.push(toolSet.upperTool);
        }
      }),
    );

    return order;
  }

  public async getToolDetails(
    toolId: string,
    station: number,
    toReturn = false,
  ): Promise<ToolDetailsInterface> {
    let lowerToolId = toolId.split('/').pop();
    const tools = await firstValueFrom(
      this.ivmToolRepository.findToolOrCustom(lowerToolId as string),
    );
    const ivmLowerTool = (toReturn ? tools : tools.filter(tool => (tool.stock ?? 0) > 0))[0];

    const tool = await firstValueFrom(this.toolApiService.find(toolId as string));
    return {
      '@id': tool!['@id'],
      'ivmTool': ivmLowerTool,
      'type': 'tool',
      'name': `${tool?.name}`,
      'qrCode': 'T' + tool?.name,
      'color': STATION_COLORS[(station as number) - 1],
      'station': station,
    };
  }

  public async getToolSetDetails(
    toolSetId: string,
    station: number,
    toReturn = false,
  ): Promise<ToolDetailsInterface[]> {
    const ivmToolSets = await firstValueFrom(
      this.ivmToolSetRepository.findByCustomToolSet(toolSetId),
    );

    const ivmToolSet = (
      toReturn ? ivmToolSets : ivmToolSets.filter(toolSet => (toolSet.stock ?? 0) > 0)
    )[0];

    const toolSet = await firstValueFrom(this.toolSetApiService.find(toolSetId as string));
    return [
      {
        '@id': toolSet!['@id'],
        'ivmTool': ivmToolSet,
        'type': 'toolset',
        'name': `${toolSet?.toolingSetId}`,
        'qrCode': ivmToolSet?.qrCode,
        'color': STATION_COLORS[(station as number) - 1],
        'station': station as number,
      },
    ];
  }

  /**
   * Updating the order status should always be a sync process.
   * 1. We save the updates in the local database;
   * 2. We send an api request to update the backend.
   */
  updateOrderStatus(order: Order, status: OrderStatus) {
    let update: Partial<Order> = { updatedAt: new Date(), status };
    if (status === 'started') update.startDateTime = new Date();

    const updatedOrder: Order = { ...order, ...update };

    return this.orderRepository
      .updateOrder(updatedOrder, {})
      .pipe(switchMap(() => this.orderApiService.updateStatus(updatedOrder)));
  }

  async updateOrderCalculationStations(order: Order, toolUpdates: ToolUpdatesInterface) {
    Object.keys(toolUpdates).map(st => {
      const stationNumber = Number(st);
      const toolUpdate = toolUpdates[stationNumber];
      //Replaced ToolSet
      order.fabricationPart.calculationStations.map((station: CalculationStation) => {
        if (station.number == stationNumber) {
          toolUpdate.removed.filter(removedTool => {
            if (removedTool === station.toolSet) {
              station.toolSet = undefined;
            }

            if (removedTool === station.customToolSet) {
              station.customToolSet = undefined;
            }

            if (removedTool.includes(ToolFilters.custom_tool)) {
              const toolIndex = station.customTools.findIndex(
                customTool => customTool === removedTool,
              );
              if (toolIndex > -1) station.customTools.splice(toolIndex, 1);
            }

            if (removedTool.includes(ToolFilters.tool)) {
              const toolIndex = station.tools.findIndex(tool => removedTool === tool);
              if (toolIndex > -1) station.tools.splice(toolIndex, 1);
            }
          });

          //Added
          station.toolSet =
            toolUpdate.added.find(tool => tool.includes(ToolFilters.toolset)) ?? station.toolSet;
          toolUpdate.added
            .filter(tool => tool.includes(ToolFilters.tool))
            .map(tl => station.tools.push(tl));
          station.customToolSet =
            toolUpdate.added.find(tool => tool.includes(ToolFilters.custom_toolset)) ??
            station.customToolSet;
          toolUpdate.added
            .filter(tool => tool.includes(ToolFilters.custom_tool))
            .map(ctl => station.customTools.push(ctl));
        }

        this.calculationStationService.update(station).subscribe();

        return station;
      });
    });

    this.orderRepository.updateOrder(order, {}).subscribe();
  }

  async removeTool(order: Order, removedTool: string, stationNumber: number) {
    order.fabricationPart.calculationStations.map((station: CalculationStation) => {
      if (station.number === stationNumber) {
        if (removedTool === station.toolSet) {
          station.toolSet = undefined;
        }

        if (removedTool === station.customToolSet) {
          station.customToolSet = undefined;
        }

        if (ToolFilters.custom_tool) {
          const toolIndex = station.customTools.findIndex(customTool => customTool === removedTool);
          if (toolIndex > -1) station.customTools.splice(toolIndex, 1);
        }

        if (removedTool.includes(ToolFilters.tool)) {
          const toolIndex = station.tools.findIndex(tool => removedTool === tool);
          if (toolIndex > -1) station.tools.splice(toolIndex, 1);
        }
      }
      this.calculationStationService.update(station).subscribe();
      return station;
    });

    this.orderRepository.updateOrder(order, {}).subscribe();
  }

  //When replacing, we usually don't want to send a sync request immediately
  async addTool(
    order: Order,
    tool: string,
    stationNumber: number,
    shouldChangeToManual = false,
    shouldSyncImmediately = true,
  ) {
    order.fabricationPart.calculationStations.map((station: CalculationStation) => {
      if (station.number == stationNumber) {
        station.toolSet = tool.includes(ToolFilters.toolset) ? tool : station.toolSet;
        if (tool.includes(ToolFilters.tool)) station.tools.push(tool);
        station.customToolSet = tool.includes(ToolFilters.custom_toolset)
          ? tool
          : station.customToolSet;
        if (tool.includes(ToolFilters.custom_tool)) station.customTools.push(tool);

        if (shouldChangeToManual) {
          station.isAutomatic = false;
        }

        if (shouldSyncImmediately) this.calculationStationService.update(station).subscribe();
      }

      return station;
    });

    this.orderRepository.updateOrder(order, {}).subscribe();
  }

  async checkForCanisterQrCode(
    code: string,
    buttons?: PopupButtons[],
    hide_cancel_button = false,
  ): Promise<boolean> {
    const ivmFastener: IvmFastener | undefined = await lastValueFrom(
      this.fastenerRepository.findByQrCode(code),
    );
    if (undefined !== ivmFastener) {
      this.dialog.open(GenericPopupComponent, {
        data: {
          title: 'Error',
          hide_cancel_button,
          messageFormatted: this.translate.instant('error.invalid_canister_qrcode_detail', {
            unit: ivmFastener?.id ?? 0,
            drawer: ivmFastener?.drawerNumber,
            locationX: ivmFastener?.coordinateX,
            locationY: LocationCoordinateMap[ivmFastener.coordinateY as string],
          }),
          buttons,
        },
      });
      return true;
    }
    const canister: StockRequest | undefined = await lastValueFrom(
      this.stockRequestService.find(code.substring(1).trim()),
    );
    if (undefined === canister) {
      this.dialog.open(GenericPopupComponent, {
        data: {
          title: 'Error',
          hide_cancel_button,
          message: this.translate.instant('error.invalid_canister'),
          buttons,
        },
      });
      return true;
    }
    return false;
  }

  updateOrder(order: Order, data: UpdateOrderParams, shouldSync = true) {
    this.orderRepository.updateOrder(order, data).subscribe(() => {
      if (shouldSync) {
        this.orderApiService.updateOrder(order, data).subscribe();
      }
    });
  }
}
