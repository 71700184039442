import { Injectable } from '@angular/core';
import { AuditTrail } from '@models/interfaces/audittrail';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailRepository {
  private table = 'auditTrail';

  constructor(private dbService: NgxIndexedDBService) {}

  create(data: AuditTrail): Observable<AuditTrail> {
    return this.dbService.add(this.table, data);
  }

  getAll(): Observable<AuditTrail[]> {
    return this.dbService.getAll<AuditTrail>(this.table);
  }

  delete(row: AuditTrail): Observable<AuditTrail[]> {
    return this.dbService.delete(this.table, row.id);
  }
}
