import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmFastener } from '@models/interfaces/ivmfastener';
import { SynchronizedIvmFastener } from '@models/interfaces/syncableivmfastener';

@Injectable({
  providedIn: 'root',
})
export class IvmFastenerApi {
  constructor(private http: HttpClient) {}

  getAll(ivm: string | undefined): Observable<HydraResponse<IvmFastener>> {
    return this.http.get<HydraResponse<IvmFastener>>(
      `${environment.apiBaseUrl}/api/ivm_fasteners?ivm=` + ivm,
    );
  }

  create(ivmFastener: SynchronizedIvmFastener): Observable<IvmFastener> {
    return this.http.post<IvmFastener>(
      `${environment.apiBaseUrl}/api/ivm_fasteners`,
      {
        ...ivmFastener,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  update(ivmFastener: IvmFastener): Observable<IvmFastener> {
    return this.http.put<IvmFastener>(
      `${environment.apiBaseUrl}${ivmFastener['@id']}`,
      {
        stock: ivmFastener.stock,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  delete(ivmFastener: IvmFastener): Observable<IvmFastener> {
    return this.http.delete<IvmFastener>(`${environment.apiBaseUrl}${ivmFastener['@id']}`, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
