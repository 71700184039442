import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { Observable } from 'rxjs';
import { AuditTrail } from '@models/interfaces/audittrail';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailApi {
  private http: HttpClient = inject(HttpClient);

  getAll(): Observable<HydraResponse<AuditTrail>> {
    return this.http.get<HydraResponse<AuditTrail>>(`${environment.apiBaseUrl}/api/audit_trails`);
  }

  create(row: AuditTrail): Observable<HydraResponse<AuditTrail>> {
    const { id, ...data } = row;
    return this.http.post<HydraResponse<AuditTrail>>(
      `${environment.apiBaseUrl}/api/audit_trails`,
      data,
    );
  }
}
