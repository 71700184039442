import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, withLatestFrom, map, mergeMap } from 'rxjs/operators';
import { PrepareOrderActions } from './actions';
import { StockMutationService } from '@services/stock/stock-mutation.service';
import { CanisterStepType, PrepareOrderState } from './reducer';
import { PrepareOrderSelectors } from './selectors';
import { forkJoin, of } from 'rxjs';
import { CassetteRepositoryService } from '@repositories/cassette/cassette-repository.service';
import { OrderRepository } from '@repositories/order/order.repository';
import { IvmFastenerRepository } from '@repositories/ivm-part/ivmfastener.repository';
import { IvmFastenerExtended } from '@models/extended/IvmFastenerExtended';
import { AuditTrailService } from '@services/audit-trail/audit-trail.service';
import { mapIvmErrorsEnum } from '@services/ivm-backend-service/events.enum';
import { OrderService } from '@services/order/order-service';
import { OrderStatus } from '@models/utils/ivm-utils';
import { IvmBackendService } from '@services/ivm-backend-service/ivm-backend.service';

@Injectable()
export class PrepareOrderEffects {
  public constructor(
    private actions$: Actions,
    private stockMutationService: StockMutationService,
    private cassetteRepository: CassetteRepositoryService,
    private orderRepository: OrderRepository,
    private store: Store<PrepareOrderState>,
    private ivmFastenerRepository: IvmFastenerRepository,
    private auditTrailService: AuditTrailService,
    private orderService: OrderService,
    private ivmBackendService: IvmBackendService,
  ) {}

  private calculateQuantity(canister: CanisterStepType): number {
    return Math.round(canister.details.currentWeight / (canister.details.weight / 1000));
  }

  public resetStateAndClearSteps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.cancel), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        // undo order stock mutations by adding new mutations to even out the changes
        prepareOrderState.canisters.list
          .filter(canister => canister.isStockMutationAdded)
          .forEach(canister => {
            if (canister.isEmpty) {
              this.ivmFastenerRepository
                .reAdd(canister.details.ivmFastener)
                .subscribe(ivmFastener => {
                  this.addCancelStockMutation(
                    canister,
                    ivmFastener,
                    prepareOrderState,
                    ivmFastener.stock!,
                  );
                });
            } else {
              this.addCancelStockMutation(
                canister,
                canister.details.ivmFastener,
                prepareOrderState,
                this.calculateQuantity(canister),
              );
            }
          });

        if (prepareOrderState.cassettes.cassette) {
          this.cassetteRepository.updateCassetteState(prepareOrderState.cassettes.cassette, 1); //Set the cassette to empty
        }

        this.auditTrailService.addLog(
          { page: 'prepare', action: 'ORDER_CANCELLED' },
          prepareOrderState.orderNumber,
        );

        if (prepareOrderState.orderNumber)
          this.revertOrderStateToNew(prepareOrderState.orderNumber);

        return of(PrepareOrderActions.reset());
      }),
    ),
  );

  private addCancelStockMutation(
    canister: CanisterStepType,
    ivmFastener: IvmFastenerExtended,
    prepareOrderState: PrepareOrderState,
    quantity: number,
  ) {
    void this.stockMutationService.modifyStock({
      ivmPart: ivmFastener,
      quantity,
      action: 'STOCK_CANCEL_RETURN',
      orderNumber: prepareOrderState.orderNumber,
    });
    void this.auditTrailService.addLog(
      {
        action: 'STOCK_MUTATION_CANCELLED',
        ivmPart: 'Canister',
        ivmPartName: ivmFastener.partName,
        amount: Number(quantity),
      },
      prepareOrderState.orderNumber,
    );
  }

  private revertOrderStateToNew(orderNumber: string) {
    this.orderRepository.getByOrderNumber(orderNumber).subscribe(order => {
      if (order) this.orderService.updateOrderStatus(order, OrderStatus.new);
    });
  }

  public addStockMutationForFasteners = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.addStockMutationForCanisterStart), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        // add records in the stock mutation
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        const quantity = this.calculateQuantity(canister); //we get the quantity used.
        if (quantity > 0)
          void this.stockMutationService.modifyStock({
            ivmPart: canister.details.ivmFastener,
            quantity: quantity * -1, //We reduce the amount so it should be negative
            action: 'STOCK_REMOVED_FOR_FILL_UNIT',
            orderNumber: prepareOrderState.orderNumber,
            deleteIfEmpty: true,
          });

        void this.auditTrailService.addLog(
          {
            action: 'STOCK_MUTATION_ACTION',
            ivmPart: 'Canister',
            ivmPartId: canister.details.ivmFastener.id as unknown as string,
            ivmPartName: canister.details.ivmFastener.partName,
            stockMutationAction: 'used',
            amount: Number(quantity),
            currentWeight: canister.details.currentWeight,
            targetWeight: canister.details.targetWeight,
            weight: canister.details.weight,
            dosingDuration: action.dosingDuration,
            slot: action.slot,
          },
          prepareOrderState.orderNumber,
        );

        return of(
          PrepareOrderActions.addStockMutationForCanisterSuccess({
            canisterIndex: action.canisterIndex,
            isEmpty: (canister.details.ivmFastener.stock ?? 0) - quantity <= 0,
          }),
        );
      }),
    ),
  );

  public canisterScanQrCodeCompletedEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterScanQrCodeCompleted),
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      mergeMap(([action, prepareOrderState]) => {
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        this.auditTrailService.addLog(
          {
            action: 'IVM_PART_SCANNED',
            ivmPart: 'Canister',
            ivmPartName: canister.details.ivmFastener.partName,
            ivmPartId: canister.details.ivmFastener['@id'] ?? '',
          },
          prepareOrderState.orderNumber,
        );
        return of(
          PrepareOrderActions.canisterPlaceInFillUnitStart({
            canisterIndex: action.canisterIndex,
          }),
        );
      }),
    ),
  );

  public canisterRestartStep3Effect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterRestartStep3),
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      mergeMap(([action, prepareOrderState]) => {
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        return of(
          PrepareOrderActions.canisterPlaceInFillUnitStart({
            canisterIndex: action.canisterIndex,
          }),
        );
      }),
    ),
  );

  public canisterSkipStep3Effect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterSkipStep3),
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      mergeMap(([action, prepareOrderState]) => {
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        return of(
          PrepareOrderActions.addStockMutationForCanisterSuccess({
            canisterIndex: action.canisterIndex,
            isEmpty: true,
          }),
          PrepareOrderActions.canisterWaitForFillingCompleted({
            canisterIndex: action.canisterIndex,
          }),
          PrepareOrderActions.canisterBackInDrawerStart({
            canisterIndex: action.canisterIndex,
            isDynamic: false,
          }),
        );
      }),
    ),
  );

  public canisterPlaceInFillUnitCompletedEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterPlaceInFillUnitCompleted), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      mergeMap(([action, prepareOrderState]) => {
        this.auditTrailService.addLog(
          {
            action: 'CANISTER_PLACED_IN_SLOT',
            ivmPart: 'Canister',
            ivmPartId:
              prepareOrderState.canisters.list[action.canisterIndex].details.ivmFastener['@id'] ??
              '',
            ivmPartName:
              prepareOrderState.canisters.list[action.canisterIndex].details.ivmFastener.partName,
            slot: prepareOrderState.canisters.list[action.canisterIndex].slot,
          },
          prepareOrderState.orderNumber,
        );
        return of(
          PrepareOrderActions.canisterWaitForFillingStart({
            canisterIndex: action.canisterIndex,
            disableQueue: action.disableQueue,
          }),
        );
      }),
    ),
  );

  private findNextToComplete(currentIndex: number, prepareOrderState: PrepareOrderState) {
    return prepareOrderState.canisters.list.findIndex(
      (cn, index) =>
        index !== currentIndex && cn.steps[2].status === 'COMPLETED' && cn.status === 'PROCESSING',
    );
  }

  private findNext(forStatus: string, currentIndex: number, prepareOrderState: PrepareOrderState) {
    return prepareOrderState.canisters.list.findIndex(
      (cn, index) => index !== currentIndex && cn.status === forStatus,
    );
  }

  /**
   * The “waiting for filling” step should be smart enough to navigate (in below order) the user to:
   * 1. another completed canister that still needs  to be put back in the drawer;
   * 2. another new canister that is yet to be scanned;
   * 3. wait for any slot to be completed and go to final step to put back in drawer;
   */
  public canisterWaitForFillingStart = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterWaitForFillingStart), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        if (action.disableQueue) {
          return of(PrepareOrderActions.emptyAction());
        }
        const filledCanisterStillInMachine = this.findNextToComplete(
          action.canisterIndex,
          prepareOrderState,
        );
        if (filledCanisterStillInMachine > -1) {
          return of(
            PrepareOrderActions.canisterBackInDrawerStart({
              canisterIndex: filledCanisterStillInMachine,
              isDynamic: true,
            }),
          );
        }

        //Action 2 : Look for a canister not yet scanned.
        const nextPendingUnfilledCanister = this.findNext(
          'PENDING',
          action.canisterIndex,
          prepareOrderState,
        );
        const hasEmptySlot: boolean = !prepareOrderState.slots.A || !prepareOrderState.slots.B;
        if (nextPendingUnfilledCanister > -1 && hasEmptySlot) {
          return of(
            PrepareOrderActions.canisterScanQrCodeStart({
              canisterIndex: nextPendingUnfilledCanister,
            }),
          );
        }

        //Action 3; Should run normally
        return of(PrepareOrderActions.emptyAction());
      }),
    ),
  );

  public errorOccurredEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.errorOccured), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        if (action.shouldAlert) {
          this.auditTrailService.addLog(
            {
              action: 'ERROR_OCCURED',
              page: 'prepare',
              error: mapIvmErrorsEnum(action.error),
            },
            prepareOrderState.orderNumber,
          );
        }

        // if there's any error in the filling step of the canister, simply retry
        const canisterIndex = prepareOrderState.canisters.list.findIndex(
          cn => cn.isLoaded === false && cn.steps[1].status === 'COMPLETED',
        );
        if (canisterIndex > -1) {
          return of(PrepareOrderActions.retryFilling({ canisterIndex: canisterIndex }));
        }

        switch (Number(action.error)) {
          case 34:
            return of(PrepareOrderActions.emergencyStop({}));
        }

        return of(PrepareOrderActions.emptyAction());
      }),
    ),
  );

  public markAsEmptyEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.markAsEmpty),
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        this.stockMutationService.modifyStock({
          ivmPart: canister.details.ivmFastener,
          quantity: 0,
          action: 'STOCK_CANISTER_EMPTY',
          orderNumber: prepareOrderState.orderNumber,
          deleteIfEmpty: true,
        });

        this.auditTrailService.addLog({
          action: 'CANISTER_MARKED_AS_EMPTY',
          ivmPart: 'Canister',
          ivmPartId: canister.details.ivmFastener['@id']!,
          ivmPartName: canister.details.ivmFastener.partName,
        });

        return of(
          PrepareOrderActions.addStockMutationForCanisterSuccess({
            canisterIndex: action.canisterIndex,
            isEmpty: true,
          }),
          PrepareOrderActions.canisterWaitForFillingCompleted({
            canisterIndex: action.canisterIndex,
          }),
          PrepareOrderActions.canisterBackInDrawerStart({
            canisterIndex: action.canisterIndex,
            isDynamic: false,
          }),
        );
      }),
    ),
  );

  public systemIsReadyEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.stationLoaded), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        //Get the canister which was filling;

        this.ivmBackendService.emitter.emit('station_loaded');

        let canisterIndex = prepareOrderState.canisters.list.findIndex(
          cn => cn.isFilling === true && cn.steps[1].status === 'COMPLETED' && cn.isLoaded === true,
        );
        if (canisterIndex > -1) {
          return of(
            PrepareOrderActions.canisterWaitForFillingCompleted({ canisterIndex }),
            PrepareOrderActions.canisterBackInDrawerStart({ canisterIndex, isDynamic: false }),
          );
        }

        return of(PrepareOrderActions.emptyAction());
      }),
    ),
  );

  /**
   * The “back in drawer” step should be smart enough to navigate (in below order) the user to:
   * 1. another completed canister that still needs  to be put back in the drawer;
   * 2. another new canister that is yet to be scanned;
   * 3. Go to final step;
   */
  public backInDrawerEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(PrepareOrderActions.canisterBackInDrawerCompleted), // this connects this effect to the action you need effects for
      withLatestFrom(this.store.select(PrepareOrderSelectors.selectState)),
      switchMap(([action, prepareOrderState]) => {
        const canister = prepareOrderState.canisters.list[action.canisterIndex];
        this.auditTrailService.addLog(
          {
            action: 'CANISTER_PLACED_IN_DRAWER',
            ivmPart: 'Canister',
            ivmPartId: canister.details.ivmFastener['@id']!,
            ivmPartName: canister.details.ivmFastener.partName,
          },
          prepareOrderState.orderNumber,
        );
        const nextToComplete: number = this.findNextToComplete(
          action.canisterIndex,
          prepareOrderState,
        );
        if (nextToComplete > -1) {
          return of(
            PrepareOrderActions.canisterBackInDrawerStart({
              canisterIndex: nextToComplete,
              isDynamic: true,
            }),
          );
        }

        const nextPending = this.findNext('PENDING', action.canisterIndex, prepareOrderState);
        const hasEmptySlot: boolean = !prepareOrderState.slots.A || !prepareOrderState.slots.B;
        if (nextPending > -1 && hasEmptySlot) {
          return of(
            PrepareOrderActions.canisterScanQrCodeStart({
              canisterIndex: nextPending,
            }),
          );
        }

        // If there is still a processing canister and there are not pending canister, we switch back to the processing
        const nextFilling = this.findNext('PROCESSING', action.canisterIndex, prepareOrderState);
        if (nextFilling > -1) {
          return of(
            PrepareOrderActions.changeCurrentCanister({
              canisterIndex: nextFilling,
            }),
          );
        }

        // Action 3; If no canisters are pending or processing anymore, go to final step
        return of(PrepareOrderActions.finalizeStart());
      }),
    ),
  );
}
