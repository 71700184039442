import { ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { AppState as RootState } from './store.reducer';

export const hydrationMetaReducer = (
  reducer: ActionReducer<RootState>,
): ActionReducer<RootState> => {
  return (state, action) => {
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem('store_state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          localStorage.removeItem('store_state');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('store_state', JSON.stringify(nextState));
    return nextState;
  };
};
