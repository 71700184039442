import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { IvmToolSet } from '@models/interfaces/ivmtoolset';

@Injectable({
  providedIn: 'root',
})
export class IvmToolSetApi {
  constructor(private http: HttpClient) {}

  getAll(ivm: string | undefined): Observable<HydraResponse<IvmToolSet>> {
    return this.http.get<HydraResponse<IvmToolSet>>(
      `${environment.apiBaseUrl}/api/ivm_tool_sets?ivm=` + ivm,
    );
  }

  create(ivmToolSet: IvmToolSet): Observable<IvmToolSet> {
    return this.http.post<IvmToolSet>(
      `${environment.apiBaseUrl}/api/ivm_tool_sets`,
      {
        ...ivmToolSet,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  update(ivmToolSet: IvmToolSet): Observable<IvmToolSet> {
    return this.http.put<IvmToolSet>(
      `${environment.apiBaseUrl}${ivmToolSet['@id']}`,
      {
        stock: ivmToolSet.stock,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }

  delete(ivmToolSet: IvmToolSet): Observable<IvmToolSet> {
    return this.http.delete<IvmToolSet>(`${environment.apiBaseUrl}${ivmToolSet['@id']}`, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
