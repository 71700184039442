import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Order } from '@models/interfaces/order';
import { OrderApi } from '@api/order.api';
import { SyncService } from '../sync-service/sync.service';
import { UpdateOrderParams } from '@models/extended/order';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  static TIMEOUT = 30; // seconds
  private orders: Observable<Order[]> | undefined;

  constructor(private orderApi: OrderApi, private syncService: SyncService) {}

  findAll(): Observable<Order[]> {
    setTimeout(() => (this.orders = undefined), OrderApiService.TIMEOUT * 1000);
    return (this.orders ??= this.orderApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  getSingle(id: string): Observable<Order | undefined> {
    return this.findAll().pipe(
      map((orders: Order[]) =>
        orders.find((order: Order) => order['@id'] === id || order['@id']?.split('/').pop() === id),
      ),
    );
  }

  findByOrderNumber(orderNumber: string): Observable<Order | undefined> {
    return this.findAll().pipe(
      map((orders: Order[]) => orders.find((order: Order) => order['orderNumber'] === orderNumber)),
    );
  }

  updateStatus(order: Order) {
    return this.orderApi.updateStatus(order);
  }

  updateOrder(order: Order, data: UpdateOrderParams) {
    return this.orderApi.update(order, data);
  }
}
