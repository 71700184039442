import { Injectable } from '@angular/core';
import { CustomToolSetApi } from '@api/custom-toolset.api';
import { CustomToolSet } from '@models/interfaces/customtoolset';
import { CustomToolSetRepository } from '@repositories/custom-toolset/custom-toolset.repository';
import { Observable, firstValueFrom, lastValueFrom, tap } from 'rxjs';
import { Ivm } from '@models/interfaces/ivm';

@Injectable({
  providedIn: 'root',
})
export class CustomToolSetService {
  private customToolSets: CustomToolSet[] = [];
  constructor(
    private customToolSetRepository: CustomToolSetRepository,
    private customToolSetApi: CustomToolSetApi,
  ) {}

  //When We add a custom toolset locally, we need to sync it to the server
  async add(ivm: Ivm) {
    const lastCustomTool = (await lastValueFrom(this.customToolSetRepository.getAll())).pop();
    const previousId = lastCustomTool ? lastCustomTool.id : 0;

    const toolingSetId = 'CUSTOM-TOOLSET-' + (previousId + 1);

    const newCustomToolset = await firstValueFrom(
      this.customToolSetRepository.add({ toolingSetId, toolingType: toolingSetId }),
    );
    this.customToolSetApi.create(toolingSetId, ivm?.['@id']).subscribe(customToolSetOnline => {
      this.customToolSetRepository.update({
        ...newCustomToolset,
        '@id': customToolSetOnline['@id'],
      });

      //This should reload the local data after syncing with the server
      this.getAll().subscribe();
    });
    await firstValueFrom(this.getAll());
    return toolingSetId;
  }

  getAll(): Observable<CustomToolSet[]> {
    return this.customToolSetRepository.getAll().pipe(
      tap(toolsets => {
        this.customToolSets = toolsets;
      }),
    );
  }

  getToolSets(): CustomToolSet[] {
    return this.customToolSets;
  }

  create(data: {
    'toolingSetId': string;
    'toolingType': string;
    '@id'?: string;
  }): Observable<CustomToolSet> {
    return this.customToolSetRepository.add(data);
  }
}
