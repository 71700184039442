import { Injectable } from '@angular/core';
import { CustomToolSet } from '@models/interfaces/customtoolset';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomToolSetRepository {
  private table = 'customToolSets';
  constructor(private dbService: NgxIndexedDBService) {}

  add(data: {
    'toolingSetId': string;
    'toolingType': string;
    '@id'?: string;
  }): Observable<CustomToolSet> {
    //We add '@id' to the row, to have the same format as toolset records, this will help us in the search, as we filter by '@id'
    return this.dbService.add(this.table, {
      ...data,
      '@id': data['@id'] ?? `/api/custom_tool_sets/${data.toolingSetId}`,
    });
  }

  getAll(): Observable<CustomToolSet[]> {
    return this.dbService.getAll<CustomToolSet>(this.table);
  }

  update(customTool: CustomToolSet) {
    return this.dbService.update(this.table, customTool).subscribe(() => {});
  }

  delete(customTool: CustomToolSet) {
    return this.dbService.delete(this.table, customTool.id).subscribe(() => {});
  }
}
