import { inject, Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { CalculationStation } from '@models/interfaces/calculationstation';
import { CalculationStationApi } from '@api/calculation-station.api';

@Injectable({
  providedIn: 'root',
})
export class CalculationStationService {
  private calculationStations$!: Observable<CalculationStation[]>;
  private calculationStationApi = inject(CalculationStationApi);

  findAll(): Observable<CalculationStation[]> {
    return (this.calculationStations$ ??= this.calculationStationApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  find(id: string): Observable<CalculationStation | undefined> {
    const matchId = (calculationStation: CalculationStation) =>
      calculationStation['@id'] === id || calculationStation['@id']?.split('/').pop() === id;
    return this.findAll().pipe(map(calculationStations => calculationStations.find(matchId)));
  }

  update(calculationStation: CalculationStation) {
    return this.calculationStationApi.update(calculationStation);
  }
}
