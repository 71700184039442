import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IvmStockMutation } from '@models/interfaces/ivmstockmutation';
import { SynchronizedIvmStockMutation } from '@models/interfaces/syncablestockmutation';
import { IvmService } from '@services/Ivm/ivm.service';
import { toIsoString } from '@models/utils/sync';

@Injectable({
  providedIn: 'root',
})
export class IvmStockMutationTransformer {
  constructor(private ivmService: IvmService) {}

  transform(ivmStockMutation: SynchronizedIvmStockMutation): Observable<IvmStockMutation> {
    console.log(ivmStockMutation);
    return this.ivmService.getIvm().pipe(
      map(ivm => ({
        ivmStockMutationId: parseInt('' + (ivmStockMutation.id ?? 0)),
        ivmPart: ivmStockMutation.ivmPart,
        createdAt: toIsoString(ivmStockMutation.createdAt as Date),
        previous: ivmStockMutation.previous === 'null' ? null : ivmStockMutation.previous,
        partName: ivmStockMutation.partName,
        quantity: ivmStockMutation.quantity,
        stockBefore: ivmStockMutation.stockBefore,
        stockAfter: ivmStockMutation.stockAfter,
        action: ivmStockMutation.action,
        order: ivmStockMutation.order,
        coordinateX: ivmStockMutation.coordinateX,
        coordinateY: ivmStockMutation.coordinateY,
        qrCode: ivmStockMutation.qrCode,
        unit: ivmStockMutation.unit,
        drawerNumber: ivmStockMutation.drawerNumber,
        ivm: ivm?.['@id'],
        stockLocation: ivmStockMutation.stockLocation,
      })),
    );
  }
}
