import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { StockRequest } from '@models/interfaces/stockrequest';
import { toIsoString } from '@models/utils/sync';
import { StockRequestFastener } from '@models/interfaces/stockrequestfastener';

@Injectable({
  providedIn: 'root',
})
export class StockRequestApi {
  constructor(private http: HttpClient) {}

  getAll(): Observable<HydraResponse<StockRequest>> {
    return this.http.get<HydraResponse<StockRequest>>(
      `${environment.apiBaseUrl}/api/stock_requests`,
    );
  }

  updateStatus(stockRequest: StockRequest): Observable<HydraResponse<StockRequest>> {
    return this.http.patch<HydraResponse<StockRequest>>(
      `${environment.apiBaseUrl}/api/stock_requests/${stockRequest['@id']?.split('/').pop()}`,
      {
        status: stockRequest.status,
        updatedAt: toIsoString(stockRequest.updatedAt || new Date()),
      },
      { headers: { 'Content-Type': 'application/merge-patch+json' } },
    );
  }

  createStockRequestFastener(stockRequest: StockRequest): Observable<StockRequestFastener> {
    return this.http.post<StockRequestFastener>(
      `${environment.apiBaseUrl}/api/stock_request_fasteners`,
      {
        fastener: stockRequest.fastener,
        emptyWeight: stockRequest.emptyWeight,
        quantity: stockRequest.quantity,
        estimatedTimeArrival: new Date(),
      },
    );
  }
}
