import { Injectable } from '@angular/core';
import { CustomTool } from '@models/interfaces/customtool';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomToolRepository {
  private table = 'customTools';

  constructor(private dbService: NgxIndexedDBService) {}

  add(data: { 'name': string; '@id'?: string }): Observable<CustomTool> {
    //We add '@id' to the row, to have the same format as tool records, this will help us in the search, as we filter by '@id'
    return this.dbService.add(this.table, {
      ...data,
      '@id': data['@id'] ?? `/api/custom_tools/${data.name}`,
    });
  }

  getAll(): Observable<CustomTool[]> {
    return this.dbService.getAll<CustomTool>(this.table);
  }

  update(customTool: CustomTool) {
    return this.dbService.update(this.table, customTool).subscribe(() => {});
  }

  delete(customTool: CustomTool) {
    return this.dbService.delete(this.table, customTool.id).subscribe(() => {});
  }
}
