import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { FastenerWeight } from '@models/interfaces/fastenerweight';

@Injectable({
  providedIn: 'root',
})
export class FastenerWeightApi {
  constructor(private http: HttpClient) {}

  getAll(): Observable<HydraResponse<FastenerWeight>> {
    return this.http.get<HydraResponse<FastenerWeight>>(
      `${environment.apiBaseUrl}/api/fastener_weights`,
    );
  }
}
