import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { Fastener } from '@models/interfaces/fastener';
import { FastenerApi } from '@api/fastener.api';
import { FastenerWeightService } from '@services/fastener-weight/fastener-weight.service';

@Injectable({
  providedIn: 'root',
})
export class FastenerService {
  constructor(private fastenerWeightService: FastenerWeightService) {}

  // we use the fastener weight api to also load the fastener partNames, to only do one heavy request and re-use it across the app
  findAll(): Observable<Fastener[]> {
    return this.fastenerWeightService.findAll().pipe(
      map(response => response.map(fastenerWeight => fastenerWeight.fastener)),
      shareReplay(1),
    );
  }

  find(id: string): Observable<Fastener | undefined> {
    return this.findAll().pipe(
      map((response: Fastener[]) =>
        response.find(
          (fastener: Fastener) =>
            fastener['@id'] === id || fastener['@id']?.split('/').pop() === id,
        ),
      ),
    );
  }
}
