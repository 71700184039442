import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { CalculationStation } from '@models/interfaces/calculationstation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalculationStationApi {
  private http: HttpClient = inject(HttpClient);

  getAll(): Observable<HydraResponse<CalculationStation>> {
    return this.http.get<HydraResponse<CalculationStation>>(
      `${environment.apiBaseUrl}/api/calculation_stations`,
    );
  }

  update(calculationStation: CalculationStation): Observable<HydraResponse<CalculationStation>> {
    const { customTools, customToolSet, tools, toolSet, isAutomatic } = calculationStation;
    return this.http.put<HydraResponse<CalculationStation>>(
      `${environment.apiBaseUrl}${calculationStation['@id']}`,
      {
        customToolSet: customToolSet ?? undefined,
        customTools,
        toolSet,
        tools,
        isAutomatic,
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }
}
