import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { User } from '@models/interfaces/user';
import { HelperService } from '../helper.service';
import { UserApi } from '@api/user.api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user!: Observable<User[]>;

  constructor(private helperService: HelperService, private userApi: UserApi) {}

  getIvmUserList(): Observable<User[]> {
    return (this.user ??= this.userApi.getAll().pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  saveSessionUser(user: User) {
    this.helperService.saveInStorage('USER_STORAGE_KEY', user);
  }

  clearSessionUser() {
    this.helperService.removeFromStorage('USER_STORAGE_KEY');
  }

  getSessionUser(): User | undefined {
    try {
      return this.helperService.getFromStorage('USER_STORAGE_KEY');
    } catch (error) {
      return undefined;
    }
  }
}
