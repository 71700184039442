export const environment = {
  production: true,
  staging: false,
  apiBaseUrl: 'https://admin.penn-connect.com',
  redirectUri: 'https://ivm.penn-connect.com/login',
  client_id: '5bf4h2ejnfskmurbdfxvjaej9xh9ewew',
  lang: ['en', 'nl'],
  ivmBackendHost: 'localhost:8082',
  githubCommit: 'd2cad5df64f617cb905a6f4df2fb961f1a976ffe',
  githubBuildDate: '2024-01-08 14:08:21',
  githubRelease: 'v0.240108.1',
  environment: 'production',
  sentryDsn:
    'https://298d255fc02414d0d1af15fa5a83661a@o4505918791024640.ingest.sentry.io/4505946456981504',
};
