import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SyncService } from './sync.service';

declare const window: Window;

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  private internalConnectionChanged = new Subject<boolean>();

  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }

  get isOnline() {
    return !!window.navigator.onLine;
  }

  constructor(private syncService: SyncService, private toastr: ToastrService) {
    window.addEventListener('online', () => this.appIsOnline());
    window.addEventListener('offline', () => console.log('offline'));
  }

  private updateOnlineStatus() {
    this.internalConnectionChanged.next(window.navigator.onLine);
  }

  appIsOnline() {
    this.toastr.info('App is back online');

    this.syncService
      .syncFailedRequests()
      .then(requestSynced => {
        if (requestSynced) {
          this.toastr.success('Sync completed');
        }
      })
      .catch(err => console.log(err));
  }
}
