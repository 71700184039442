import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { IvmTool } from '@models/interfaces/ivmtool';
import { IvmToolApi } from '@api/ivm-tool.api';
import { HydraResponse } from '@models/response/HydraResponse';
import { SynchronizedIvmTool } from '@models/interfaces/syncableivmtool';
import { Ivm } from '@models/interfaces/ivm';
import { IvmPart } from '@models/interfaces/ivmpart';
import { IvmToolRepository } from '@repositories/ivm-part/ivmtool.repository';
import { BaseIvmPartService } from '..';

@Injectable({
  providedIn: 'root',
})
export class IvmToolService extends BaseIvmPartService {
  override api_url: string = '/api/ivm_tools';
  private ivmTools?: Observable<IvmTool[]>;

  constructor(private ivmToolApi: IvmToolApi, private ivmToolRepository: IvmToolRepository) {
    super();
  }

  findAll(ivm: string): Observable<IvmTool[]> {
    return (this.ivmTools ??= this.ivmToolApi.getAll(ivm).pipe(
      map(response => response['hydra:member']),
      shareReplay(1),
    ));
  }

  findById(id: number, ivm: string): Observable<IvmTool | undefined> {
    return this.findAll(ivm).pipe(
      map((ivmTools: IvmTool[]) => ivmTools.find((ivmTool: IvmTool) => ivmTool['ivmPartId'] == id)),
    );
  }

  create(ivmTool: IvmTool): Observable<IvmTool> {
    return this.ivmToolApi.create(ivmTool).pipe(tap(() => (this.ivmTools = undefined)));
  }

  async findOrCreate(ivmPart: IvmTool, ivmObject?: Ivm) {
    const ivm = ivmObject?.['@id']?.split('/').pop() ?? '';
    let ivmToolBackend;
    ivmToolBackend = await lastValueFrom(this.findById(ivmPart.id ?? 0, ivm));

    if (ivmToolBackend === undefined) {
      let ivmToolObj: SynchronizedIvmTool = {
        ivmPartId: ivmPart.id ?? 0,
        qrCode: ivmPart.qrCode,
        partName: ivmPart.partName,
        coordinateX: ivmPart.coordinateX,
        coordinateY: ivmPart.coordinateY,
        drawerNumber: ivmPart.drawerNumber,
        unit: ivmPart.unit ?? 0,
        stock: ivmPart.stock ?? 0,
        tool: ivmPart.tool,
        stockLocation: ivmPart.stockLocation,
        ivm: ivmObject?.['@id'] ?? '',
      };
      ivmToolBackend = await firstValueFrom(this.create(ivmToolObj));
    }

    return ivmToolBackend;
  }

  update(ivmPart: IvmTool) {
    this.ivmToolRepository.update(ivmPart);
    if (ivmPart['@id']) this.ivmToolApi.update(ivmPart).subscribe();
  }

  delete(ivmPart: IvmTool) {
    this.ivmToolRepository.delete(ivmPart);
    if (ivmPart['@id']) this.ivmToolApi.delete(ivmPart).subscribe();
  }
}
