import { Injectable } from '@angular/core';

export enum StorageKeyEnum {
  USER_STORAGE_KEY = 'USER_STORAGE_KEY',
  STARTED_ORDER = 'STARTED_ORDER',
}

export type StorageKey = keyof typeof StorageKeyEnum;

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Save data in local storage
   * The data can be of any type, so we convert it in string before saving
   * @param key
   * @param data
   */
  saveInStorage<T>(key: StorageKey, data: T) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * get data from localstorage, if it doesn't exist return null
   * @param key
   */
  getFromStorage<T>(key: StorageKey): T {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    else throw new Error(`Data does not exist for ${key}`);
  }

  removeFromStorage(key: StorageKey) {
    localStorage.removeItem(key);
  }
}
