import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { ToolSet } from '@models/interfaces/toolset';
import { CustomToolSetService } from './custom-toolset.service';

@Injectable({
  providedIn: 'root',
})
export class ToolSetApiService {
  private toolsets$!: Observable<ToolSet[]>;
  constructor(private http: HttpClient, private customToolSetService: CustomToolSetService) {}

  getAll(): Observable<ToolSet[]> {
    return (this.toolsets$ ??= this.http
      .get<HydraResponse<ToolSet>>(`${environment.apiBaseUrl}/api/tool_sets`)
      .pipe(
        map(response => response['hydra:member']),
        shareReplay(1),
        map(response => [...response, ...this.customToolSetService.getToolSets()]),
      ));
  }

  find(id: string): Observable<ToolSet | undefined> {
    return this.getAll().pipe(
      map((toolSets: ToolSet[]) =>
        toolSets.find(
          (toolSet: ToolSet) => toolSet['@id'] === id || toolSet['@id']?.split('/').pop() === id,
        ),
      ),
    );
  }

  findByToolingSetId(id: string): Observable<ToolSet | undefined> {
    return this.getAll().pipe(
      map((toolSets: ToolSet[]) =>
        toolSets.find((toolset: ToolSet) => toolset['toolingSetId'] === id),
      ),
    );
  }
}
