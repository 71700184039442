import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HydraResponse } from '@models/response/HydraResponse';
import { toIsoString } from '@models/utils/sync';
import { IvmStockMutation } from '@models/interfaces/ivmstockmutation';
import { SynchronizedIvmStockMutation } from '@models/interfaces/syncablestockmutation';

type Nullable<T> = T | null;
@Injectable({
  providedIn: 'root',
})
export class IvmStockMutationApi {
  constructor(private http: HttpClient) {}

  getAll(ivm: string | undefined): Observable<HydraResponse<IvmStockMutation>> {
    return this.http.get<HydraResponse<IvmStockMutation>>(
      `${environment.apiBaseUrl}/api/ivm_stock_mutations?ivm=${ivm}`,
    );
  }

  create(
    ivmStockMutation: Omit<SynchronizedIvmStockMutation, 'id' | 'qrCode' | 'synced'>,
  ): Observable<IvmStockMutation> {
    return this.http.post<IvmStockMutation>(
      `${environment.apiBaseUrl}/api/ivm_stock_mutations`,
      {
        ...ivmStockMutation,
        createdAt: toIsoString(new Date()),
      },
      { headers: { 'Content-Type': 'application/json' } },
    );
  }
}
