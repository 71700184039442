import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IvmStockMutation } from '@models/interfaces/ivmstockmutation';
import { Observable } from 'rxjs';
import { SynchronizedIvmStockMutation } from '@models/interfaces/syncablestockmutation';
import { IvmStockMutationActionType } from '@models/utils/ivm-utils';
import { IvmFastenerExtended } from '@models/extended/IvmFastenerExtended';
import { IvmTool } from '@models/interfaces/ivmtool';
import { IvmToolSet } from '@models/interfaces/ivmtoolset';
import { IvmCassette } from '@models/interfaces/ivmcassette';
import { Order } from '@models/interfaces/order';

type AddStockMutationParams = {
  '@id'?: string;
  'ivmPartId': string;
  'action': IvmStockMutationActionType;
  'quantity': number;
  'previousStock': number;
  'currentStock': number;
  'previous'?: string;
  'orderUuid'?: string | undefined; // backend uuid, ex. /api/orders/uuid
  'createdAt'?: Date | string;
  'synced'?: 0 | 1;
  'ivmPart': IvmFastenerExtended | IvmTool | IvmToolSet | IvmCassette;
  'ivmStockMutationId'?: number;
};
@Injectable({
  providedIn: 'root',
})
export class StockMutationRepositoryService {
  private table = 'stockMutation';
  constructor(private dbService: NgxIndexedDBService) {}

  addStockMutation(params: AddStockMutationParams): Observable<SynchronizedIvmStockMutation> {
    const {
      ivmPart,
      action,
      quantity,
      previousStock = 0,
      currentStock = quantity,
      previous = null,
      synced = 0,
      createdAt = new Date(),
      orderUuid,
      ivmPartId,
      ivmStockMutationId,
    } = params;

    const data: SynchronizedIvmStockMutation = {
      '@id': params['@id'],
      'qrCode': ivmPart.qrCode,
      'ivmPart': ivmPartId,
      'previous': previous,
      'partName': ivmPart.partName,
      'quantity': quantity,
      'stockBefore': previousStock,
      'stockAfter': currentStock,
      'action': action,
      'synced': synced,
      'createdAt': createdAt,
      'coordinateX': ivmPart.coordinateX,
      'coordinateY': ivmPart.coordinateY,
      'unit': ivmPart.unit,
      'drawerNumber': ivmPart.drawerNumber,
      'stockLocation': ivmPart.stockLocation,
      'order': orderUuid ?? null,
      ...(ivmStockMutationId && { id: ivmStockMutationId }),
    };
    return this.dbService.add('stockMutation', data);
  }

  getByQrCode(code: string): Observable<IvmStockMutation> {
    return this.dbService.getByIndex('stockMutation', 'qrCode', code);
  }

  getByIvmPart(ivmPart: string): Observable<SynchronizedIvmStockMutation[]> {
    return this.dbService.getAllByIndex('stockMutation', 'ivmPart', IDBKeyRange.only(ivmPart));
  }

  findByOrder(orderId: string): Observable<SynchronizedIvmStockMutation[]> {
    return this.dbService.getAllByIndex('stockMutation', 'order', IDBKeyRange.only(orderId));
  }

  getAllStockMutations(): Observable<SynchronizedIvmStockMutation[]> {
    return this.dbService.getAll<SynchronizedIvmStockMutation>('stockMutation');
  }

  getAllUnsyncedStockMutations(): Observable<SynchronizedIvmStockMutation[]> {
    return this.dbService.getAllByIndex(this.table, 'synced', IDBKeyRange.only(0));
  }

  findByPartName(partName: string): Observable<SynchronizedIvmStockMutation[]> {
    return this.dbService.getAllByIndex('stockMutation', 'partName', IDBKeyRange.only(partName));
  }

  update(stockMutation: SynchronizedIvmStockMutation) {
    return this.dbService.update(this.table, stockMutation);
  }

  clearStockMutation() {
    return this.dbService.clear('stockMutation');
  }
}
